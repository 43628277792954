export default {
  path: 'generated-reports',
  component: () =>
    import(
      /* webpackChunkName: "GeneratedReports" */ '../../views/dashboard/generated_reports/generatedReportList.vue'
    ),
  name: 'generated-reports',
  meta: {
    page_title: 'Generated Reports',
    is_wrapper: true,
    plugin: 'report',
  },
};
