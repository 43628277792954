export class WebSocketConnection {
  constructor() {
    this.socket = null;
    this.pingIntervalId = null;
    this.pingTimeoutId = null;

    this.pingInterval = 30000; // 30 seconds
    this.pingTimeout = 10000; // 10 seconds
  }

  createSocketConnection(id, url, wsData) {
    if (!url || !id) {
      console.log("WebSocket url and User id is required", url, id);
      return;
    }

    this.socket = new WebSocket(url);

    const payload_dict = {
      id: {
        merchant_id: process.env.MERCHANT_ID,
        type: "frontend",
      },
      audience: {
        data: id.toString(),
      },
    };

    const pingWebsocket = () => {
      this.pingIntervalId = setInterval(() => {
        this.socket.send(JSON.stringify({ id: { type: "ping" } }));
        this.pingTimeoutId = setTimeout(() => {
          if (this.socket.readyState === WebSocket.OPEN) {
            clearInterval(this.pingIntervalId);
            clearTimeout(this.pingTimeoutId);
            // call back here to make sure we don't send a timeout
            pingWebsocket();
          } else {
            this.socket.close();
            this.socket = new WebSocket(url);
          }
        }, this.pingTimeout);
      }, this.pingInterval);
    };

    this.socket.onopen = (event) => {
      pingWebsocket();
      this.socket.send(JSON.stringify(payload_dict));
    };

    this.socket.onmessage = (event) => {
      if (event.data === "pong" || !event.data) {
        clearTimeout(this.pingTimeoutId);
      } else {
        wsData.pop();
        wsData.push(event.data);
      }
    };

    this.socket.onerror = (event) => {
      console.log("error occurs", event);
      wsData.pop();
      wsData.push(
        JSON.stringify({
          error: true,
        })
      );
      clearInterval(this.pingIntervalId);
    };

    this.socket.onclose = () => {
      console.log("Connection is closed...");
      clearInterval(this.pingIntervalId);
    };
  }

  closeSocketConnection() {
    if (this.socket) {
      this.socket.close();
      this.socket = null;
    }
  }
}
