<template>
  <div class="navigation-version-text">navigation v{{ frontEndVersion }}</div>
</template>

<script>
import packageInfo from '../../package';

export default {
  name: 'Version',
  data() {
    return {
      frontEndVersion: packageInfo.version,
    };
  },
};
</script>

<style scoped>
.navigation-version-text {
  position: fixed;
  bottom: 1px;
  right: 50%;
  z-index: 900;
  color: transparent;
  height: 30px;
}
</style>
