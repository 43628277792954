export default {
  path: 'real-estate',
  component: {
    template: `<router-view></router-view>`,
  },
  name: 'real-estate',
  meta: {
    page_title: 'real-estate',
    is_wrapper: true,
  },
  children: [
    {
      path: 'transactions',
      name: 'real-estate-transactions',
      component: () =>
        import(
          /* webpackChunkName: "RealEstate" */ '../../views/dashboard/real_estate/RealEstateTransaction'
        ),
      meta: {
        page_title: 'Transactions',
        plugin: 'real_estate',
        breadcrumb_items: [
          'general-info',
          'real-estate',
          'real-estate-transactions',
        ],
      },
    },
    {
      path: 'transactions/:id',
      name: 'real-estate-transactions-details',
      component: () =>
        import(
          /* webpackChunkName: "RealEstate" */ '../../views/dashboard/real_estate/RealEstateTransactionDetail'
        ),
      meta: {
        plugin: 'real_estate',
      },
    },
    {
      path: 'deleted-transactions',
      name: 'real-estate-deleted',
      component: () =>
        import(
          /* webpackChunkName: "RealEstate" */ '../../views/dashboard/real_estate/RealEstateDeleteTransaction'
        ),
      meta: {
        page_title: 'Deleted Paid Transactions',
        breadcrumb_items: [
          'general-info',
          'real-estate',
          'real-estate-deleted',
        ],
        plugin: 'real_estate',
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'real-estate-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "RealEstate" */ '../../views/dashboard/real_estate/RealEstateDeletedTransactionDetails'
        ),
    },
    {
      path: 'table-headers',
      name: 'real-estate-settings-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/real_estate/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: 'Table Headers',
        breadcrumb_items: ['general-info', 'real-estate', 'table-headers'],
      },
    },
  ],
};
