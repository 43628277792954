<template>
    <v-container fluid>
        <SectionHeader/>
        <v-layout row wrap justify-center>
            <v-flex xs12 sm12 lg6>
                <v-card>
                    <v-card-title>
                        <h3>{{ $t("Product Bulk Create") }}</h3>
                        <v-spacer></v-spacer>
                        <div class="text-xs-center">
                            <v-menu offset-y>
                                <template v-slot:activator="{ on }">
                                    <v-btn
                                            color="primary"
                                            dark
                                            v-on="on"
                                    >
                                        {{$t('Download example')}}<v-icon right dark>get_app</v-icon>
                                    </v-btn>
                                </template>
                                <v-list>
                                    <v-list-tile
                                            v-for="item in $options.example_file_formats"
                                            :key="item.param"
                                            :href="$options.productExampleLink+'?type='+item.param"
                                            download
                                    >
                                        <v-list-tile-title>{{ getExampleFileFormats[item.param] }}</v-list-tile-title>
                                    </v-list-tile>
                                </v-list>
                            </v-menu>
                        </div>
                    </v-card-title>
                    <v-card-text>
                        <v-form
                            ref="form"
                            v-model="valid"
                            lazy-validation
                            @submit="submit"
                        >
                            <v-flex class="text-start">
                                <v-flex class="text-start">
                                    <v-layout row wrap>
                                        <upload-btn
                                            class="upload-btn"
                                            :multiple="false"
                                            :loading="processing"
                                            :title="$t('Select bulk file')"
                                            @file-update="fileChanged"
                                            ref="attachment_uploader"
                                        >
                                        </upload-btn>
                                        <v-btn flat icon v-if="file" @click="clearAttachment">
                                            <v-icon>clear</v-icon>
                                        </v-btn>
                                        <v-spacer/>
                                        <v-btn v-if="validation_errors.error_file_url" :href="validation_errors.error_file_url">
                                            {{$t('Download error reports')}}
                                        </v-btn>
                                    </v-layout>
                                    <span v-if="validation_errors.file" class="v-messages error--text">
                                            {{validation_errors.file}}
                                    </span>
                                    <div class="text-xs-center">
                                        <v-btn
                                                type="submit"
                                                :disabled="processing"
                                                class="success-color"
                                        >
                                            {{ $t("Submit") }}
                                        </v-btn>
                                    </div>
                                </v-flex>
                            </v-flex>
                        </v-form>
                    </v-card-text>
                </v-card>
            </v-flex>
        </v-layout>
        <v-dialog
                v-model="err_report_dialog"
                transition="dialog-bottom-transition"
                max-width="80%"
        >
            <v-data-table
                    :headers="errorDialogHeaders"
                    :items="validation_errors.errors"
                    hide-actions
            >
                <template v-slot:items="props">
                    <td class="text-xs-left" v-for="h in errorDialogHeaders" :key="h.value">
                        {{ Array.isArray(props.item[h.value]) ? props.item[h.value].join(',\n') : props.item[h.value] }}
                    </td>
                </template>
            </v-data-table>
        </v-dialog>
    </v-container>
</template>

<script>
    import UploadButton from 'vuetify-upload-button';
    import SectionHeader from "../../../components/SectionHeader";
    import {productExampleLink} from "../../../services/plugins/food_ordering";
    import food_ordering from "../../../services/plugins/food_ordering";

    export default {
        name: "ProductBulkCreate",
        components: {SectionHeader, 'upload-btn': UploadButton},
        data: () => ({
            valid: true,
            file: null,
            validation_errors: {},
            error_file_url: null,
            err_report_dialog: false
        }),
        computed: {
            errorDialogHeaders(){
                const err_report = this.validation_errors.errors || [];
                if(err_report.length < 1) { return [] }
                return Object.keys(err_report[0]).map(k => ({text: k, value: k}))
            },
            getExampleFileFormats(){
                return {
                    "required_fields": this.$t("Required fields"),
                    "all_fields": this.$t("All fields")
                }
            }
        },
        methods: {
            fileChanged (file) {
                this.file = file;
            },
            clearAttachment(){
                this.file = null;
                this.$refs.attachment_uploader.clear()
            },
            async submit(e){
                e.preventDefault();
                const data = {file: this.file};
                try {
                    const res = await this.$processReq(food_ordering.uploadProductBulk(data), false, false)
                    this.$root.$emit('snackbar', { text: this.$t(res.data.message), color: 'success'});
                    this.$refs.form.reset();
                    this.validation_errors.error_file_url = null;
                    this.$refs.attachment_uploader.clear()
                } catch (err) {
                    this.validation_errors = err.response.data;
                    if (!this.validation_errors.file){
                        this.err_report_dialog = true;

                    }
                }
            }
        },
        created() {

        },
        example_file_formats: [
            {label: 'Required fields', param: 'required_fields'},
            {label:'All fields', param:'all_fields'},
        ],
        productExampleLink: productExampleLink
    }
</script>

<style scoped>
.upload-btn {
  box-shadow: unset;
  background: unset;
}
.success-color {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
</style>
