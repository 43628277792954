<template>
  <v-container fluid>
    <SectionHeader />
    <v-layout>
      <div class="camera-div">
        <p class="decode-result">
          <b>{{ result }}</b>
        </p>
        <qrcode-stream :camera="camera" @init="onInit" @decode="onDecode">
          <div v-if="validationSuccess" class="validation-success">{{ result }}</div>
          <div
              v-if="validationFailure"
              class="validation-failure"
          >
            {{result}}
          </div>
          <div v-if="validationPending" class="validation-pending">validation in progress...</div>
        </qrcode-stream>
      </div>
      <v-dialog v-model="dialog" width="500">
        <v-card>
          <v-card-title>{{$t("Result")}}</v-card-title>
          <v-card-text v-if="result !== undefined && result !== ''">
            {{result}}
          </v-card-text>
          <v-card-text v-else>
            {{data}}
          </v-card-text>
        </v-card>
      </v-dialog>
      <v-dialog v-model="showPermissionsDialog" width="500">
        <v-card>
          <v-card-text>
            {{$t("Camera Access Needed") }}
            <br>
            {{ $t("We need access to your camera for this feature to work.") }}
          </v-card-text>
        </v-card>
      </v-dialog>
    </v-layout>
  </v-container>
</template>

<script>
import SectionHeader from "@/components/SectionHeader";
import FullfilmentMixin from "@/mixins/FullfilmentMixin";
import food_ordering from "../../../services/plugins/food_ordering";

export default {
  name: "OrderFullfilment",
  components: {SectionHeader},
  mixins: [FullfilmentMixin],
  methods: {
    async validateQRCode(qrCode){
      let isValidJson = true;
      let qrCodeData = {}
      try {
        qrCodeData = JSON.parse(qrCode);
      }
      catch (err) {
        isValidJson = false;
      }
      if (!isValidJson || !qrCodeData.hasOwnProperty('id')){
        this.result = this.$t("Invalid QR Code");
        this.isValid = false;
        this.openResultDialog();
      }
      else {
        const data = qrCodeData.id;
        try {
          const res = await food_ordering.checkIfUserHasScanPermission(data)
          if (res.data.has_permission){
            this.$router.push({name: "order-details", params: {id: qrCodeData.id}}).catch(err => {
              console.log(err)
            });
          }
        } catch (err) {
          console.log("Error: ", err)
          this.$root.$emit("snackbar", {text: err.response.data.message, color: "error"})
        }

      }

    }
  },
  watch: {
    dialog: function (new_value, old_value){
      if (!new_value && old_value){
        this.isValid = true;
        this.result = undefined;
      }
    }
  }
}
</script>

<style scoped>

</style>
