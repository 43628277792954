<template>
  <v-layout row>
    <v-layout align-start column>
      <h2 class="display-2">{{ $t($route.meta.page_title) }}</h2>
      <v-breadcrumbs :items="breadcrumb_items">
        <template v-slot:divider>
          <v-icon>fiber_manual_record</v-icon>
        </template>
        <template v-slot:item="props">
          <router-link
            :to="props.item.to"
            :class="['breadcrumb-item-custom', props.item.disabled && 'disabled']"
          >
            {{ props.item.text }}
          </router-link>
        </template>
      </v-breadcrumbs>
    </v-layout>
    <HistoryDialog/>
  </v-layout>
</template>

<script>
  import HistoryDialog from './HistoryDialog.vue'

  export default {
    name: "SectionHeader",
    components: {HistoryDialog},
    computed: {
      breadcrumb_items(){
        if(this.$route.meta.breadcrumb_items && this.$route.meta.breadcrumb_items.length)
          return this.$route.meta.breadcrumb_items.map((item, index) => {
            const route = this.$router.resolve({name: item}).route;
            if(route && route.matched.length > 0){
              const targetRoute = route.matched[route.matched.length-1];
              return {
                text: this.$t(targetRoute.meta.page_title) || item,
                to: {name: item, params: this.$route.params},
                disabled: targetRoute.meta.is_wrapper || (index === (this.$route.meta.breadcrumb_items.length-1))
              }
            } else {
              return {text: this.$t(item), to: '/', disabled: true}
            }
          })
        else
          return []
      }
    }
  }
</script>

<style scoped>

</style>
