import Vue from "vue";
import VueRouter from "vue-router";
import { i18n } from "../plugins/i18n";
import NotFound from '../views/errors/NotFound.vue';
import newFoodOrder from './customer/new_food_order'
import foodOrdering from './dashboard/food_ordering'

Vue.use(VueRouter)

const router = new VueRouter({
  mode: 'history',
  routes: [
    {
      path: '/:lang',
      component: { template: '<router-view />' },
      children: [
        newFoodOrder,
        foodOrdering
      ]
    },
    {
      path: '*',
      name: '404',
      component: NotFound,
    }
  ]
})

router.beforeEach((to, from, next) => {
  // i18n logic ::::::::::::::::::::::::::::::::::::::::::::
  const lang = to.params.lang;
  if (i18n.locale !== lang) {
    i18n.locale = lang
  }
  if (to.matched.some((record) => record.meta.requiresAuth)) {
    if (!Vue.prototype.$token()) {
      Vue.prototype.$logout();
      return;
    }
  }
  return next();
});

export default router
