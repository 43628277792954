export default {
  path: 'new-menu',
  name: 'new-food-orders',
  component: () => import(/* webpackChunkName: "new-food-orders" */"../../views/customer/food_orders/new/FoodOrders"),
  children: [
    {
      path: '',
      name: 'new-menu',
      component: () => import(/* webpackChunkName: "new-menu" */"../../views/customer/food_orders/new/OrderMenu"),
    },
    {
      path: 'category/:categoryId/food-details/:id',
      name: 'food-details',
      component: () => import(/* webpackChunkName: "payment-result" */"../../views/customer/food_orders/new/FoodDetails"),
    },
    // {
    //     path: 'food-details',
    //     name: 'food-details',
    //     component: () => import(/* webpackChunkName: "food-details" */"../../views/customer/food_orders/new/FoodDetails"),
    // },
    {
      path: 'branches',
      name: 'new-branches',
      component: () => import(/* webpackChunkName: "new-branches" */"../../views/customer/food_orders/new/Branches"),
    },
    {
      path: 'checkout',
      name: 'new-checkout',
      component: () =>
        import( /* webpackChunkName: "checkout" */ "../../views/customer/food_orders/new/Checkout"),
    },
    {
      path: 'order-confirmation/:number',
      name: 'new-order-confirmation',
      component: () =>
        import( /* webpackChunkName: "new-order-confirmation" */ "../../views/customer/food_orders/new/AfterOrderConfirmation"),
    },

  ]
}
