
// For storing values which you don't save to localstorage
const state = {
    showMenuItemDialog: false,
    showCategoryItemsGrid: false,
    selectedCategory: undefined,
    allCategories: [],
    isPromotionSectionSelected: [],
    showImageModal: false,
    showDeliveryModal: false
}

const actions = {
    setShowCategoryItemsGrid({commit}, data){
        commit('setShowCategoryItemsGrid', data);
    },
    setSelectedCategory({commit}, data){
        commit('setSelectedCategory', data)
    },
    setAllCategories({commit}, data){
        commit('setAllCategories', data);
    },
    setIsPromotionSelected({commit}, data){
        commit('setIsPromotionSelected', data);
    },
    setShowImageModal({commit}, value){
        commit('setShowImageModal', value);
    },
    setShowDeliveryModal({commit}, value){
        commit('setShowDeliveryModal', value);
    },
    setShowMenuItemDialog({commit}, value){
        commit('setShowMenuItemDialog', value);
    }
}

const mutations = {
    setShowCategoryItemsGrid(state, data){
        state.showCategoryItemsGrid = data;
    },
    setSelectedCategory(state, data){
        state.selectedCategory = data;
    },
    setAllCategories(state, data){
        state.allCategories = data;
    },
    setIsPromotionSelected(state, data){
        state.isPromotionSectionSelected = data;
    },
    setShowImageModal(state, value){
        state.showImageModal = value;
    },
    setShowDeliveryModal(state, value){
        state.showDeliveryModal = value;
    },
    setShowMenuItemDialog(state, value){
        state.showMenuItemDialog = value;
    },
}

export default {
    namespaced: true,
    state,
    actions,
    mutations
}
