import mitt from 'mitt';

const emitter = mitt();

const Mitt = {
  install(app) {
    app.config.globalProperties.$emitter = emitter;
  },
};

export default Mitt;
