import { i18n } from '../../plugins/i18n';

export default {
  path: 'catalogue',
  component: () =>
    import(
      /* webpackChunkName: "CatalogueCustomer" */ '../../views/customer/catalogue/Catalogue'
    ),
  name: 'catalogue',
  meta: {
    plugin: 'catalogue',
  },
  children: [
    {
      path: 'product-list',
      name: 'catalogue-product-list',
      component: () =>
        import(
          /* webpackChunkName: "catalogue-product-list" */ '../../views/customer/catalogue/ProductsList.vue'
        ),
      meta: {
        page_title: i18n.tc('Catalogue'),
        plugin: 'catalogue',
      },
    },
    {
      path: 'product-detail/:slug',
      name: 'catalogue-product-detail',
      component: () =>
        import(
          /* webpackChunkName: "catalogue-product-details" */ '../../views/customer/catalogue/ProductDetails.vue'
        ),
      meta: {
        page_title: i18n.tc('Product details'),
        breadcrumb_items: [
          'catalogue-product-list',
          'catalogue-product-detail',
        ],
        plugin: 'catalogue',
      },
    },
    {
      path: 'cart',
      name: 'cart',
      component: () =>
        import(
          /* webpackChunkName: "cart" */ '../../views/customer/catalogue/Cart'
        ),
      meta: {
        page_title: i18n.tc('Cart'),
        breadcrumb_items: ['catalogue-product-list', 'cart'],
        plugin: 'catalogue',
      },
    },
    {
      path: 'checkout',
      name: 'checkout',
      component: () =>
        import(
          /* webpackChunkName: "checkout" */ '../../views/customer/catalogue/Checkout'
        ),
      meta: {
        page_title: i18n.tc('Checkout'),
        breadcrumb_items: ['catalogue-product-list', 'cart', 'checkout'],
        plugin: 'catalogue',
      },
    },
  ],
};
