import { i18n } from '../../plugins/i18n';

export default {
  path: 'event',
  component: {
    template: `<router-view></router-view>`,
  },
  name: 'event',
  meta: {
    page_title: i18n.tc('Events'),
    is_wrapper: true,
    plugin: 'event',
  },
  children: [
    {
      path: 'transactions',
      name: 'events-transactions',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/Transactions'
        ),
      meta: {
        page_title: i18n.tc('Transactions'),
        breadcrumb_items: ['general-info', 'events', 'events-transactions'],
        plugin: 'event',
      },
    },
    {
      path: 'transactions/:id',
      name: 'events-transactions-details',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/TransactionDetails'
        ),
      meta: {
        plugin: 'event',
      },
    },
    {
      path: 'deleted-transactions',
      name: 'events-deleted',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/DeletedTransactions'
        ),
      meta: {
        page_title: i18n.tc('Deleted Paid Transactions'),
        breadcrumb_items: ['general-info', 'events', 'events-deleted'],
        plugin: 'event',
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'event-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/DeletedTransactionDetails'
        ),
      meta: {
        plugin: 'event',
      },
    },
    {
      path: 'bookings',
      name: 'bookings',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/BookingsList'
        ),
      meta: {
        page_title: i18n.tc('Bookings'),
        breadcrumb_items: ['general-info', 'events', 'bookings'],
        plugin: 'event',
      },
    },
    {
      path: 'bookings/:id',
      name: 'booking-details',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/BookingDetails'
        ),
      meta: {
        plugin: 'event',
      },
    },
    {
      path: 'create',
      name: 'booking-create',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/BookingCreate'
        ),
      meta: {
        page_title: i18n.tc('Create Bookings'),
        breadcrumb_items: ['general-info', 'bookings', 'booking-create'],
        plugin: 'event',
      },
    },
    {
      path: 'fullfilment',
      name: 'fullfilment',
      component: () =>
        import(
          /* webpackChunkName: "EventDashboard" */ '../../views/dashboard/events/Fullfilment'
        ),
      meta: {
        page_title: i18n.tc('Fullfilment'),
        breadcrumb_items: ['general-info', 'events', 'fullfilment'],
        plugin: 'event',
      },
    },
    // {
    //     path: 'bulk-create',
    //     name: 'bulk-create',
    //     component: BookingBulkCreate,
    //     meta: {
    //         page_title: i18n.tc('Booking Bulk Create'),
    //         breadcrumb_items: ['general-info', 'bookings', 'bulk-create'],
    //         plugin: 'events'
    //     }
    // }
    {
      path: 'table-headers',
      name: 'events-settings-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/events/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: i18n.tc('Table Headers'),
        breadcrumb_items: ['general-info', 'events', 'table-headers'],
      },
    },
  ],
};
