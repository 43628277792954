import {Service, AuthorizedService} from "../index";

export const PluginsSerice = (url) => AuthorizedService('/plugins'+url);

export const DashboardService = () => AuthorizedService('/dashboard');

export const CustomerPluginsService = (url) => Service('/plugins'+url);

const pluginsService = PluginsSerice('');

export const getInstalledPlugins = () => pluginsService.get('/installed/');

export const getSectionsConfig = () => pluginsService.get('/sections/');
