import Vue from 'vue';
import Vuex from 'vuex';
import cart from './modules/cart';
import dashboard from './modules/dashboard';
import categories_products from './modules/categories_products';
import ticket from './modules/ticket';
import customer from './modules/customer';
Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    cart,
    dashboard,
    categories_products,
    ticket,
    customer,
  },
  strict: debug,
  plugins: [],
});
