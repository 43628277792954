import { i18n } from '../../plugins/i18n';

export default {
  path: 'ticket',
  component: {
    template: `<router-view></router-view>`,
  },
  name: 'ticket',
  meta: {
    page_title: 'Ticket',
    is_wrapper: true,
  },
  children: [
    {
      path: 'approve-permissions',
      name: 'approve-permissions',
      component: () =>
        import(
          /* webpackChunkName: "TicketPermsList" */ '../../views/dashboard/ticket/permissionsRequestList.vue'
        ),
      meta: {
        page_title: i18n.tc('Permissions Requests'),
        breadcrumb_items: ['general-info', 'ticket', 'approve-permissions'],
        plugin: 'permissions_approval',
      },
    },
    {
      path: 'approve-refund-void',
      name: 'approve-refund-void',
      component: () =>
        import(
          /* webpackChunkName: "TicketActionsList" */ '../../views/dashboard/ticket/refundVoidRequestList.vue'
        ),
      meta: {
        page_title: i18n.tc('Operations Requests'),
        breadcrumb_items: ['general-info', 'ticket', 'approve-refund-void'],
        plugin: 'operations_approval',
      },
    },
  ],
};
