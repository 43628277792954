import { defineStore } from 'pinia';

export const useGlobalVariableStore = defineStore('global_variables', {
  state: () => ({
    coreBackendUrl: '',
    realEstateApiGatewayUrl: '',
    merchantId: '',
    isShowFromforPaymentPage: true,
    checkoutSDKLink: '',
    socketConnection: () => {},
    wsConnectionUrl: '',
    payButtonText: null,
    displayRejectButton: false,
    posPluginLanguageButton: true,
    checkoutPluginLanguageButton: true,
  }),
  getters: {
    getCoreBackendUrl: (state) => state.coreBackendUrl,
    getRealEstateApiGatewayUrl: (state) => state.realEstateApiGatewayUrl,
    getMerchantId: (state) => state.merchantId,
    showFromforPaymentPage: (state) => state.isShowFromforPaymentPage,
    getCheckoutSDKLink: (state) => state.checkoutSDKLink,
    getWSConnectionInstance: (state) => state.socketConnection,
    getWSConnectionUrl: (state) => state.wsConnectionUrl,
    getPayButtonText: (state) => state.payButtonText,
    getDisplayRejectButton: (state) => state.displayRejectButton,
    getPosPluginLanguageButton: (state) => state.posPluginLanguageButton,
    getCheckoutPluginLanguageButton: (state) =>
      state.checkoutPluginLanguageButton,
  },
  actions: {
    setCoreBackendUrl(val) {
      this.coreBackendUrl = val;
    },
    setRealEstateApiGatewayUrl(val) {
      this.realEstateApiGatewayUrl = val;
    },
    setMerchantId(val) {
      this.merchantId = val;
    },
    setIsShowFromForPaymentPage(val) {
      this.isShowFromforPaymentPage = val;
    },
    setCheckoutSDKLink(val) {
      this.checkoutSDKLink = val;
    },
    setSocketConnectionInstance(val) {
      this.socketConnection = val;
    },
    setWSConnectionUrl(val) {
      this.wsConnectionUrl = val;
    },
    setPayButtonText(val) {
      this.payButtonText = val;
    },
    setDisplayRejectButton(val) {
      this.displayRejectButton = val;
    },
    setPosPluginLanguageButton(val) {
      this.posPluginLanguageButton = val;
    },
    setCheckoutPluginLanguageButton(val) {
      this.checkoutPluginLanguageButton = val;
    },
  },
});
