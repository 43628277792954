<template>
  <v-app id="app_frontend" :class="classes">
    <router-view />
    <Snackbar></Snackbar>
    <Version></Version>
  </v-app>
</template>

<script>
import Vue from 'vue';
import Snackbar from './components/Snackbar';
import Version from './components/Version';

export default {
  props: {
    navbar: Boolean,
  },
  components: { Snackbar, Version },
  computed: {
    classes() {
      return (
        !(
          Vue.prototype.$noNavBar ||
          this.$route.name === 'login' ||
          this.$route.name === 'forget-password'
        ) && 'navbarShow p-b-50 p-t-90'
      );
    },
  },
};
</script>

<style scoped>
.p-b-50 {
  padding-bottom: 50px;
}
.p-t-90 {
  padding-top: 90px;
}
.navbarShow {
  padding-left: 300px;
}

.application--is-rtl.navbarShow {
  padding-left: 0px;
  padding-right: 300px;
}
@media screen and (max-width: 600px) {
  .navbarShow {
    padding-left: 15px;
  }
}

@media screen and (max-width: 1263px) {
  .navbarShow {
    padding-left: 30px;
  }
  .application--is-rtl.navbarShow {
    padding-left: 0px;
    padding-right: 0px;
  }
}
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
  /*margin-top: 60px;*/
}
</style>