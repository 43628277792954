const state = {
  dashboard_permissions: {
    fetched: false,
    isSuperUser: false,
    perms: {},
  },
  token: null,
  username: '',
  email: '',
  merchant_logo: '',
  merchant_title: '',
  send_order_notification: false,
  id: '',
  sections: {},
  install_plugins: {},
};

const getters = {
  hasPerm: (state) => (name) => {
    return (
      state.dashboard_permissions.isSuperUser ||
      state.dashboard_permissions.perms[name] === true
    );
  },
  hasHeadersPerm: (state) => (plugin_name) => {
    if (
      state.dashboard_permissions.isSuperUser ||
      (Object.keys(state.dashboard_permissions.perms).length > 0 &&
        state.dashboard_permissions.perms[
          `change_${plugin_name}transactionproxyfield`
        ] !== undefined)
    ) {
      return true;
    } else false;
  },
  isLoggedIn(state) {
    return state.token !== null;
  },
  hasSection: (state) => (section) => {
    return state.sections[section];
  },
  userId(state) {
    return state.id;
  },
  email(state) {
    return state.email;
  },
  getInstallPlugins(state) {
    return state.install_plugins;
  },
  getToken(state) {
    return state.token;
  },
  getSections: (state) => {
    return state.sections;
  },
};

const mutations = {
  setPermissions(state, perms) {
    const { isSuperUser, permsArray } = perms;
    state.dashboard_permissions.fetched = true;
    state.dashboard_permissions.isSuperUser = isSuperUser;
    state.dashboard_permissions.perms = permsArray.reduce(
      (perms_obj, perm) => {
        perms_obj[perm] = true;
        return perms_obj;
      },
      {},
    );
  },
  setUsername(state, username) {
    state.username = username;
  },
  setEmail(state, email) {
    state.email = email;
  },
  setSentOrderNotification(state, sendNotification) {
    state.send_order_notification = sendNotification;
  },
  setId(state, id) {
    state.id = id;
  },
  setToken(state, token) {
    state.token = token;
    localStorage.removeItem('sentToServer');
  },
  setSections(state, data) {
    state.sections = data;
  },
  setInstallPlugins(state, installedPlugins) {
    state.install_plugins = installedPlugins;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
