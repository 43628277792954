import { i18n } from '../../plugins/i18n';

export default {
  path: 'customer-payment',
  component: {
    template: `<router-view></router-view>`,
  },
  name: 'customer-payment',
  meta: {
    page_title: 'Customer Payment',
    is_wrapper: true,
  },
  children: [
    {
      path: 'transactions',
      name: 'customer-payment-transactions',
      component: () =>
        import(
          /* webpackChunkName: "CustomerPayment" */ '../../views/dashboard/customer_payment/Transactions'
        ),
      meta: {
        page_title: i18n.tc('Transactions'),
        plugin: 'customer_payment',
        breadcrumb_items: [
          'general-info',
          'customer-payment',
          'customer-payment-transactions',
        ],
      },
    },
    {
      path: 'transactions/:id',
      name: 'customer-payment-transactions-details',
      component: () =>
        import(
          /* webpackChunkName: "CustomerPayment" */ '../../views/dashboard/customer_payment/TransactionDetails'
        ),
      meta: {
        plugin: 'customer_payment',
      },
    },
    {
      path: 'deleted-transactions',
      name: 'customer-payment-deleted',
      component: () =>
        import(
          /* webpackChunkName: "CustomerPayment" */ '../../views/dashboard/customer_payment/DeletedTransactions'
        ),
      meta: {
        page_title: i18n.tc('Deleted Paid Transactions'),
        breadcrumb_items: [
          'general-info',
          'customer-payment',
          'customer-payment-deleted',
        ],
        plugin: 'customer_payment',
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'customer-payment-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "CustomerPayment" */ '../../views/dashboard/customer_payment/DeletedTransactionDetails'
        ),
    },
    {
      path: 'payment-restriction',
      name: 'customer-payment-restriction',
      component: () =>
        import(
          /* webpackChunkName: "PaymentRestriction" */ '../../views/dashboard/customer_payment/PaymentRestriction'
        ),
      meta: {
        page_title: i18n.tc('Payment Restriction'),
        breadcrumb_items: [
          'general-info',
          'customer-payment',
          'customer-payment-restriction',
        ],
        plugins: 'customer_payment',
      },
    },
    {
      path: 'table-headers',
      name: 'customer-payment-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/customer_payment/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: i18n.tc('Table Headers'),
        breadcrumb_items: ['general-info', 'customer-payment', 'table-headers'],
      },
    },
  ],
};
