export class LocalStorageWrapper {
  constructor() {
    this.isLocalStorageAvailable = this.checkLocalStorageAvailability();
  }

  checkLocalStorageAvailability() {
    try {
      const testKey = "__storage_test__";
      localStorage.setItem(testKey, testKey);
      localStorage.removeItem(testKey);
      return true;
    } catch (e) {
      return false;
    }
  }

  setItem(key, value) {
    if (this.isLocalStorageAvailable) {
      localStorage.setItem(key, value);
    } else {
      console.warn("localStorage is not available.");
    }
  }

  getItem(key) {
    if (this.isLocalStorageAvailable) {
      return localStorage.getItem(key);
    } else {
      console.warn("localStorage is not available.");
      return null;
    }
  }

  removeItem(key) {
    if (this.isLocalStorageAvailable) {
      localStorage.removeItem(key);
    } else {
      console.warn("localStorage is not available.");
    }
  }

  clear() {
    if (this.isLocalStorageAvailable) {
      localStorage.clear();
    } else {
      console.warn("localStorage is not available. clear is a no-op.");
    }
  }
}
