import axios from 'axios';
import store from '../store';
import router from '../router';
import { API_ORIGIN } from '../services';

export const getToken = () => {
  return localStorage.getItem('VUE_APP_TOKEN');
};
export const setToken = (token, refresh) => {
  store.commit('dashboard/setToken', token);
  localStorage.setItem('VUE_APP_TOKEN', token);
  localStorage.setItem('refresh', refresh);
};

export const getJWToken = (username, password) => {
  return axios({
    baseURL: `${API_ORIGIN}/api/v1/user/token/`,
    method: 'post',
    params: {
      format: 'json',
    },
    data: { username, password },
    withCredentials: !parseInt(process.env.VUE_APP_IS_CROSSDOMAIN),
  }).then((res) => {
    if ('enable_OTP' in res.data && res.data.enable_OTP === true) {
      return res;
    }
    const d = new Date();
    d.setTime(d.getTime() + Number(process.env.VUE_APP_SESSION_IDLE));
    const expires = 'expires=' + d.toUTCString();
    document.cookie =
      'sessionid=' + res.data.sessionid + ';' + expires + ';path=/';
    setToken(res.data.access, res.data.refresh);
    return res;
  });
};

export const verifyOtp = (username, code) => {
  return axios({
    baseURL: `${API_ORIGIN}/api/v1/user/token/api/v1/user/verify-otp/`,
    method: 'post',
    params: {
      format: 'json',
    },
    data: { code, username },
    withCredentials: !parseInt(process.env.VUE_APP_IS_CROSSDOMAIN),
  }).then((res) => {
    let d = new Date();
    d.setTime(d.getTime() + Number(process.env.VUE_APP_SESSION_IDLE));
    let expires = 'expires=' + d.toUTCString();
    document.cookie =
      'sessionid=' + res.data.sessionid + ';' + expires + ';path=/';
    setToken(res.data.access, res.data.refresh);
    return res;
  });
};
let requestingToken = false; //this important so no multible refreshToken reqeust is done in parellel
//only one at the time
export const refreshToken = (refresh) => {
  return new Promise((resolve, reject) => {
    if (!requestingToken) {
      requestingToken = true;
      console.log('reqeusting new token.');
      return axios({
        baseURL: `${API_ORIGIN}/api/v1/user/token/refresh/`,
        method: 'post',
        params: {
          format: 'json',
        },
        data: { refresh },
        withCredentials: !parseInt(process.env.VUE_APP_IS_CROSSDOMAIN),
      })
        .then((res) => {
          setToken(res.data.access, res.data.refresh);
          let d = new Date();
          d.setTime(d.getTime() + Number(process.env.VUE_APP_SESSION_IDLE));
          resolve();
        })
        .catch((error) => {
          console.log('error while refreshing token', error);
          reject();
        })
        .finally(() => {
          requestingToken = false;
        });
    } else {
      setInterval(() => {
        if (!requestingToken) resolve();
      }, 100);
    }
  });
};

export const resetPassword = (password, token) => {
  return axios({
    baseURL:
      localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN') +
      '/api/v1/user/password-reset/confirm/',
    method: 'post',
    data: { password, token },
  }).then((res) => {
    return res.data;
  });
};

export const forgetPassword = (username) => {
  return axios({
    baseURL:
      localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN') +
      '/api/v1/user/forgot-password',
    method: 'post',
    data: { username },
  }).then((res) => {
    return res.data;
  });
};

export const validateToken = (token) => {
  return axios({
    baseURL:
      localStorage.getItem('VUE_APP_CORE_SERVER_ORIGIN') +
      '/api/v1/user/password-reset/validate_token/',
    method: 'post',
    data: { token },
  }).then((res) => {
    return res.data;
  });
};

export const isLoggedIn = () => {
  const access_token = getToken();
  return !!access_token;
};

export const parseJwt = (token) => {
  try {
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
      window
        .atob(base64)
        .split('')
        .map(function (c) {
          return '%' + ('00' + c.charCodeAt(0).toString(16)).slice(-2);
        })
        .join(''),
    );

    return JSON.parse(jsonPayload);
  } catch {
    return JSON.parse('{}');
  }
};

export const logout = () => {
  localStorage.removeItem('VUE_APP_TOKEN');
  localStorage.removeItem('refresh');
  router.push({ name: 'login' }).catch((err) => {
    if (
      err &&
      err.name !== 'NavigationDuplicated' &&
      !err.message.includes('Avoided redundant navigation to current location')
    ) {
      console.log('Error: ', err);
    }
  });
};
