import { i18n } from '../../plugins/i18n';

export default {
  path: 'bulk',
  component: () =>
    import(
      /* webpackChunkName: "Bulk" */ '../../views/dashboard/bulk/Bulk.vue'
    ),
  name: 'bulk',
  meta: {
    page_title: 'Bulk',
    is_wrapper: true,
  },
  children: [
    {
      path: 'uploads',
      name: 'bulk-uploads',
      component: () =>
        import(
          /* webpackChunkName: "BulkUploads" */ '../../views/dashboard/bulk/BulkUploads.vue'
        ),
      meta: {
        page_title: 'Bulk Uploads',
        breadcrumb_items: ['general-info', 'bulk', 'bulk-uploads'],
      },
    },
    {
      path: 'uploads/:bulk_id',
      name: 'bulk-details',
      component: () =>
        import(
          /* webpackChunkName: "BulkDetails" */ '../../views/dashboard/bulk/BulkDetails.vue'
        ),
      meta: {
        page_title: i18n.tc('Bulk Details'),
        breadcrumb_items: [
          'general-info',
          'bulk',
          'bulk-uploads',
          'bulk-details',
        ],
        plugin: 'bulk',
      },
    },
    {
      path: 'uploads/:bulk_id/dispatch-logs',
      name: 'bulk-dispatch-logs',
      component: () =>
        import(
          /* webpackChunkName: "BulkLogs" */ '../../views/dashboard/bulk/BulkLogsList.vue'
        ),
      meta: {
        page_title: i18n.tc('Bulk Dispatch logs'),
        breadcrumb_items: [
          'general-info',
          'bulk',
          'bulk-uploads',
          'bulk-details',
          'bulk-dispatch-logs',
        ],
        plugin: 'bulk',
      },
    },
    {
      path: 'uploads/:bulk_id/dispatch-logs/:dispatch_log_id/transaction-logs',
      name: 'bulk-transaction-logs',
      component: () =>
        import(
          /* webpackChunkName: "BulkTransactionLogs" */ '../../views/dashboard/bulk/BulkTransactionLogs.vue'
        ),
      meta: {
        page_title: 'Bulk Transaction logs',
        breadcrumb_items: [
          'general-info',
          'bulk',
          'bulk-uploads',
          'bulk-details',
          'bulk-dispatch-logs',
          'bulk-transaction-logs',
        ],
      },
    },
    {
      path: 'transactions',
      name: 'bulk-payment-transactions',
      component: () =>
        import(
          /* webpackChunkName: "BulkTransactions" */ '../../views/dashboard/bulk/Transactions'
        ),
      meta: {
        page_title: i18n.tc('Transactions'),
        plugin: 'bulk',
        breadcrumb_items: ['general-info', 'bulk', 'bulk-payment-transactions'],
      },
    },
    {
      path: 'transactions/:id',
      name: 'bulk-transactions-details',
      component: () =>
        import(
          /* webpackChunkName: "BulkTransactinDetails" */ '../../views/dashboard/bulk/BulkPymtTxnDetails'
        ),
      meta: {
        plugin: 'bulk',
      },
    },
    {
      path: 'deleted-transactions',
      name: 'bulk-payment-deleted',
      component: () =>
        import(
          /* webpackChunkName: "BulkDeletedTransactions" */ '../../views/dashboard/bulk/DeletedTransactions'
        ),
      meta: {
        page_title: i18n.tc('Deleted Paid Transactions'),
        breadcrumb_items: ['general-info', 'bulk', 'bulk-payment-deleted'],
        plugin: 'bulk',
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'bulk-payment-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "BulkDeletedTransactionDetails" */ '../../views/dashboard/bulk/DeletedPymtTxnDetails'
        ),
    },
    {
      path: 'table-headers',
      name: 'bulk-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/bulk/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: i18n.tc('Table Headers'),
        breadcrumb_items: ['general-info', 'bulk', 'table-headers'],
      },
    },
  ],
};
