import Vue from 'vue';

import store from '../store';

export default class SessionStateWorker {
  constructor() {
    this.worker = new SharedWorker('/scripts/sessionStateWorker.js', {
      type: 'module',
    });
    this.worker.port.start();

    this.worker.port.addEventListener('message', (event) => {
      switch (event.data.action) {
        case 'updateCountdown':
          store.commit('dashboard/setIdleCountdownTimer', event.data.countdown);
          break;

        case 'stopIdleCountdown':
          store.commit('dashboard/setIdleCountdownTimer', 60);
          break;

        case 'logout':
          Vue.prototype.$logout();
          break;

        case 'refresh':
          Vue.prototype
            .$refreshToken(
              !Vue.prototype.$isKeycloakAuth
                ? localStorage.getItem('refresh')
                : null,
            )
            .then()
            .catch(() => {
              this.logoutAcrossTabs();
            });
          break;

        default:
          break;
      }
    });
  }

  sendMessage(message) {
    this.worker?.port.postMessage(message);
  }

  logoutAcrossTabs() {
    this.worker?.port.postMessage({ action: 'logout' });
    Vue.prototype.$logout();
  }
}
