import {
  PRIMARY_IMPORT_MAP,
  PRIMARY_CDN_PACKAGES,
  FALLBACK_IMPORT_MAP,
  FALLBACK_CDN_PACKAGES,
} from "../constants";
import { renderMountErrorMessage } from "../helper/utils";
import { LocalStorageWrapper } from "../helper/localStorageWrapper";

const storage = new LocalStorageWrapper();

async function loadScripts(importMap, packageUrls) {
  const importMapScript = document.createElement("script");
  importMapScript.type = "systemjs-importmap";
  importMapScript.textContent = JSON.stringify(importMap);
  document.head.appendChild(importMapScript);

  const scriptPromises = packageUrls.map((url) => {
    return new Promise((resolve, reject) => {
      const script = document.createElement("script");
      script.src = url;
      script.onload = resolve;
      script.onerror = reject;
      document.head.appendChild(script);
    });
  });

  await Promise.all(scriptPromises);

  try {
    // eslint-disable-next-line no-undef
    await System.import("@ottu-mf/root-config");
    storage.removeItem("SINGLE_SPA_MOUNT_ATTEMPTS");
  } catch (err) {
    console.log(err);
    const numberOfAttempts = storage.getItem("SINGLE_SPA_MOUNT_ATTEMPTS");

    const hasExceededRetryLimit = Number(numberOfAttempts) >= 3;
    if (!hasExceededRetryLimit) {
      storage.setItem(
        "SINGLE_SPA_MOUNT_ATTEMPTS",
        Number(numberOfAttempts) + 1
      );
      window.location.reload();
    } else {
      renderMountErrorMessage();
      storage.removeItem("SINGLE_SPA_MOUNT_ATTEMPTS");
    }
  }
}

async function initializeScripts() {
  try {
    await loadScripts(PRIMARY_IMPORT_MAP, PRIMARY_CDN_PACKAGES);
  } catch (err) {
    console.log(err);
    loadScripts(FALLBACK_IMPORT_MAP, FALLBACK_CDN_PACKAGES);
  }
}

initializeScripts();
