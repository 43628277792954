<template>
  <div class="version-text">
    fnb_frontend v{{ frontEndVersion }}
  </div>
</template>

<script>
import packageInfo from '../../package';

export default {
  name: "Version",
  data() {
    return {
      frontEndVersion: packageInfo.version,
      showFrontendVersion: false,
    };
  }
};
</script>

<style>
.version-text {
  position: fixed;
  bottom: 1px;
  right: 4px;
  z-index: 900;
  color: transparent;
  height: 30px;
  width: 50px;
}
</style>
