import {i18n} from "../../plugins/i18n";

const state = {
    items: [],
    checkoutStatus: null,
    branch: null,
    delivery_area: null,
    items_inventory: {},
    min_order: null,
    unread_notifications: 0,
    updateOrderList: false,
    originalFilename: '',
    isCatering: false,
    platesNumber: 1,
    voucher: undefined,
    catering_categories: [],
    delivery_type: 'pickup',
    delivery_type_label: undefined,
    pinned: false
}

function isAddOnsEquals(arr1, arr2) {
    if (arr1.length !== arr2.length)
        return false;

    let isEqual = true;
    arr1 = arr1.map(a => a.id).sort();
    arr2 = arr2.map(a => a.id).sort();

    for (let i = 0; i < arr1.length; i++){
        if (arr1[i] !== arr2[i]){
            isEqual = false;
        }
    }

    return isEqual
}

function isOptionsEquals(arr1, arr2) {

    if (arr1.length !== arr2.length)
        return false;

    let isEqual = true;

    Object.keys(arr1).forEach(index =>{
        if (arr1[index].id !== arr2[index].id){
            isEqual = false;
        }
    })

    return isEqual
}

function notInCart(cartItems, item) {
    let result = undefined;
    cartItems.forEach(cartItem => {
        if (isAddOnsEquals(cartItem.addOns, item.addOns) && isOptionsEquals(cartItem.options?cartItem.options:[],item.options?item.options:[]) && cartItem.special_instruction === item.special_instruction){
            result = cartItem;
        }
    })
    return result;
}

if(localStorage.getItem('food_cart')) {
    let restoredCart = {};
    try{
        restoredCart = JSON.parse(localStorage.getItem('food_cart'));
    } catch (e) {
        localStorage.removeItem('food_cart')
    }
    if(restoredCart.items){
        restoredCart.items.forEach(item=>{
            if(item.inventory===null){ item.inventory = Infinity }
        })
    }

    state.delivery_type = restoredCart.delivery_type;

    Object.assign(state, restoredCart);
}
if (localStorage.getItem('unread_notifications')){
    state.unread_notifications = +localStorage.getItem('unread_notifications');
}

if (localStorage.getItem('branch')){
    state.branch = +localStorage.getItem('branch');
}
if (localStorage.getItem('delivery_area')){
    state.delivery_area = +localStorage.getItem('delivery_area');
}
if (localStorage.getItem('isCatering')){
    state.isCatering = JSON.parse(localStorage.getItem('isCatering'));
}

if (localStorage.getItem('voucher')){
    state.voucher = JSON.parse(localStorage.getItem('voucher'));
}

if (localStorage.getItem('delivery_type')){
    state.delivery_type = localStorage.getItem('delivery_type');
}

if (localStorage.getItem('pinned')){
    state.pinned = localStorage.getItem('pinned');
}

const getters = {
    cartProducts: (state) => {
        return state.items.map(({ details, quantity, uId, addOns,options }) => {
            let price = details.price_with_discount?details.price_with_discount:details.product.price
            let total = price * quantity
            if (details.addOns.length){
                total += details.addOns.reduce((sum, addOn) => {
                    return sum + parseFloat(addOn.price) * quantity
                }, 0);
            }
            let addOnSum = details.addOns.reduce((sum, addOn) => {
                return sum + parseFloat(addOn.price)
            }, 0);
            return {
                id: details.id,
                name: details.product.name[i18n.locale],
                price: details.product.price,
                price_with_addons: parseFloat(price) + addOnSum,
                quantity,
                addOns,
                options,
                images: details.product.product_images,
                product: details.product.id,
                total: total,
                price_with_discount: details.price_with_discount,
                special_instruction: details.special_instruction,
                inventory: details.inventory,
                uId
            }
        })
    },
    cateringCartProducts: (state) => {
        return state.catering_categories.map(category => {
            let items = category.items.map(item => {
                let total = parseFloat(item.price) * category.number_of_plates;
                return {
                    name: item.name,
                    price: parseFloat(item.price),
                    id: item.id,
                    uId: item.uId,
                    images: item.images,
                    total: total
                }
            })
            return {
                id: category.id,
                name: category.name,
                minimum_number_of_plates: category.minimum_number_of_plates,
                number_of_plates: category.number_of_plates,
                service_charges: parseFloat(category.service_charges),
                items: items
            }
        })
    },
    cartTotalPrice: (state, getters) => {
        let sum = getters.cartProducts.reduce((total, product) => {
            return total + product.currentPrice;
        },0)
        // let sum = getters.cartProducts.reduce((total, product) => {
        //     let addOns = [...product.addOns];
        //     let quantity = state.isCatering ? state.platesNumber : product.quantity;
        //     let addOnsTotal = addOns.reduce((sum, addOn) => {
        //         return sum + parseFloat(addOn.price) * quantity
        //     }, 0);
        //     let price = product.price_with_discount?product.price_with_discount:product.price
        //     return total + price * quantity + addOnsTotal;
        // }, 0)
        if (state.voucher && state.voucher.discount_type !== 'no_discount'){
            if (state.voucher.discount_type === 'percentage'){
                sum -= (sum * +state.voucher.amount) / 100;
            }
            else {
                sum -= state.voucher.amount;
            }
        }
        return sum;
    },
    cateringCartTotalPrice: (state, getters) => {
        let sum = getters.cateringCartProducts.reduce((total, category) => {
            let itemsTotal = category.items.reduce((total, product) => {
                return total + product.price * category.number_of_plates;
            }, 0);
            return total + itemsTotal; //+ category.service_charges;
        }, 0);
        if (state.voucher){
            if (state.voucher.discount_type === 'percentage'){
                sum -= (sum * +state.voucher.amount) / 100;
            }
            else {
                sum -= state.voucher.amount;
            }
        }
        return sum;
    },
    getCateringServiceCharge: (state, getters) => {
        let charge = getters.cateringCartProducts.reduce((total, category) => {
            return total + parseFloat(category.service_charges)
        }, 0);
        
        return charge;
    },
    cateringCartProductsLength:(state, getters) => {
        return getters.cateringCartProducts.reduce((total, category) => {
            return total + category.items.length
        }, 0);
    },
    getCateringCheckoutItems: () =>{
        return state.catering_categories.map((category) => {
            let items = category.items.map(item => item.id);
            if(items.options){
                Object.keys(items.options).map((key) => {
                    items.options[key] =  items.options[key].id;
                  });
            }
            return {
                category: category.id,
                number_of_plates: category.number_of_plates,
                menu_items: items
            }
        })
    },
    getBranch(){
        return state.branch
    },
    getDeliveryArea(){
        return state.delivery_area
    },
    checkoutItems: (state) => {
        return state.items.map(({ details, quantity, addOns, options }) => {
            let addOnsIds = addOns.map(addOn => addOn.id);
            let option = {}
            if(options && Object.keys(options).length > 0)
                Object.keys(options).map((key) => {
                    option[key] =  options[key].id;
                });
          return {
              menu_item: details.id,
              special_instructions: details.special_instruction,
              quantity,
              add_ons: addOnsIds,
              options: option
          }
        })
    },
    getUnreadNotifications: (state) => {
        return state.unread_notifications;
    }
};

const actions = {
    addProductToCart ({ state, commit }, item) {
        commit('setCheckoutStatus', null);
        if (item.quantity > 0 || item.quantity===null) {
            const cartItems = state.items.filter(prod => prod.id === item.id);
            if (!cartItems.length) {
                commit('pushProductToCart', { details: item })
            } else {
                let cartItem = notInCart(cartItems, item);
                if (!cartItem) {
                    commit('pushProductToCart', { details: item })
                }
                else{
                    commit('addAdditionalQty', {uId: cartItem.uId, quantity: item.quantity})
                }
            }
        }
        commit('saveCart');
    },
    addCateringProductToCart({state, commit}, item){
        const cartCategories = state.catering_categories.filter(category => category.id === item.category.id);
        if (!cartCategories.length){
            commit('addCateringCategoryToCart', {details: item})
        }
        else{
            commit('addItemToCateringCategory', {details: item})
        }
        commit('saveCart')
    },
    removeProductFromCart({commit}, uId) {
        commit('removeProductFromCart', uId);
        commit('saveCart');
    },
    removeProductFromCategory({commit}, data){
        commit('removeProductFromCategory', data);
        commit('saveCart');
    },
    incrementItemQuantity({commit}, {uId}){
        commit('incrementItemQuantity', {uId});
        commit('saveCart');
    },
    decrementItemQuantity ({commit}, { uId }){
        commit('decrementItemQuantity', {uId});
        commit('saveCart');
    },
    clearCart({commit}){
        commit('clearCart');
        localStorage.removeItem('food_cart')
    },
    setMinOrder({commit}, min_order){
        commit('setMinOrder', min_order);
    },
    incrementUnreadNotification({commit}){
        commit('incrementUnreadNotification');
        commit('saveUnreadNotification');
    },
    setUnreadNotificationToZero({commit}){
        commit('setUnreadNotificationToZero');
        commit('saveUnreadNotification');
    },
    setUpdateOrderState({commit}, value){
        commit('setUpdateOrderList', value);
    },
    setOriginalFilename({commit}, value){
        commit('setOriginalFilename', value)
    },
    setIsCatering({commit}, value){
        commit('setIsCatering', value);
    },
    setPlatesNumber({commit}, value){
        commit('setPlatesNumber', value);
        commit('saveCart');
    },
    setVoucher({commit}, value){
        commit('setVoucher', value)
    },
    incrementPlatesAmount({commit}, id){
        commit('incrementPlatesAmount', id);
        commit('saveCart');
    },
    decrementPlatesAmount({commit}, id){
        commit('decrementPlatesAmount', id);
        commit('saveCart');
    },
    setDeliveryType({ commit }, value){
        commit('setDeliveryType', value);
    },
    setDeliveryTypeLabel({ commit }, value){
        commit('setDeliveryTypeLabel', value);
    },
    setPinned({commit}, value){
        commit('setPinned', value);
        commit('saveCart');
    }
}

const mutations = {
    pushProductToCart (state, { details }) {
        if (state.items_inventory.hasOwnProperty(details.id)){
            state.items_inventory[details.id] -= details.quantity;
        }
        else {
            state.items_inventory[details.id] = details.inventory - details.quantity;
        }
        state.items.push({
            id: details.id,
            details,
            quantity: details.quantity,
            addOns: details.addOns,
            images: details.product.product_images,
            special_instruction: details.special_instruction,
            options: details.options,
            uId: state.items.length + 1
        })
    },
    removeProductFromCart(state, uId) {
        let item = state.items.filter(item => item.uId === uId.uId);
        delete state.items_inventory[item[0].id];
        state.items = state.items.reduce((items, item) => {
            if (item.uId !== uId.uId) {items.push(item)}
            return items
        }, [])
    },
    addCateringCategoryToCart(state, {details}){
        let price = parseFloat(details.price_with_discount?details.price_with_discount:details.product.price)

        if(details.addOns.length > 0)
            price = details.addOns.reduce((total,addOn)=>{
                return total + parseFloat(addOn.price)
            },price)
        
        state.catering_categories.push({
            id: details.category.id,
            name: details.category.name,
            minimum_number_of_plates: details.category.minimum_number_of_plates,
            number_of_plates: details.category.minimum_number_of_plates,
            service_charges: details.category.service_charges,
            items: [{
                id: details.id,
                images: details.product.product_images,
                uId: 1,
                options: details.options,
                price: price,//details.price_with_discount?details.price_with_discount:details.product.price,
                name: details.product.name
            }]
        })
    },
    addItemToCateringCategory(state, {details}){
        let price = parseFloat(details.price_with_discount?details.price_with_discount:details.product.price)

        if(details.addOns.length > 0)
            price = details.addOns.reduce((total,addOn)=>{
                return total + parseFloat(addOn.price)
            },price)
        
        let category = state.catering_categories.find(category => category.id === details.category.id)
        category.items.push({
            id: details.id,
            images: details.product.product_images,
            uId: category.items.length + 1,
            name: details.product.name,
            options: details.options,
            price //details.price_with_discount?details.price_with_discount:details.product.price,
        })
    },

    incrementItemQuantity (state, { uId }) {
        const cartItem = state.items.find(item => item.uId === uId);
        cartItem.quantity++;
        state.items_inventory[cartItem.id]--;
        // cartItem.details.inventory--
    },
    decrementItemQuantity (state, { uId }) {
        const cartItem = state.items.find(item => item.uId === uId);
        cartItem.quantity--;
        state.items_inventory[cartItem.id]++;
        // cartItem.details.inventory++;
    },
    addAdditionalQty(state, { uId, quantity }){
        const cartItem = state.items.find(item => item.uId === uId);
        cartItem.quantity += quantity;
        state.items_inventory[cartItem.id] += quantity;
    },
    setCartItems (state, { items }) {
        state.items = items
    },
    setCheckoutStatus (state, status) {
        state.checkoutStatus = status
    },
    saveCart (state){
        localStorage.setItem('food_cart', JSON.stringify(state));
    },
    clearCart (state){
        state.items = [];
        state.items_inventory = [];
        state.catering_categories = [];
    },
    setBranch(state, branch){
        state.branch = branch;
        localStorage.setItem('branch', branch);
    },
    setDeliveryArea(state, delivery_area){
        state.delivery_area = delivery_area;
        localStorage.setItem('delivery_area', delivery_area);
    },
    setMinOrder(state, min_order){
        state.min_order = min_order;
    },
    incrementUnreadNotification(state){
        state.unread_notifications++;
    },
    setUnreadNotificationToZero(state){
        state.unread_notifications = 0;
    },
    saveUnreadNotification(state){
        localStorage.setItem('unread_notifications', state.unread_notifications);
    },
    setUpdateOrderList(state, value){
        state.updateOrderList = value;
    },
    setOriginalFilename(state, value){
        state.originalFilename = value;
    },
    setIsCatering(state, value){
        state.isCatering = value;
        localStorage.setItem('isCatering', state.isCatering);
    },
    setPlatesNumber(state, value){
        state.platesNumber = value;
    },
    setVoucher(state, value){
        state.voucher = value;
        localStorage.setItem('voucher', JSON.stringify(value))
    },

    setDeliveryType(state, value){
        state.delivery_type = value;
        localStorage.setItem('delivery_type', value);
    },
    setDeliveryTypeLabel(state, value){
        state.delivery_type_label = value;
        localStorage.setItem('delivery_type_label', value);
    },
    setPinned(state, value){
        state.pinned = value;
    },
    incrementPlatesAmount(state, id){
        let category = state.catering_categories.find(category => category.id === id);
        category.number_of_plates += 1;
    },
    decrementPlatesAmount(state, id){
        let category = state.catering_categories.find(category => category.id === id);
        category.number_of_plates -= 1;
    },
    removeProductFromCategory(state, data){
        let selected_category = state.catering_categories.find(category => category.id === data.category);
        selected_category.items = selected_category.items.reduce((items, item) => {
            if (item.id !== data.id) {items.push(item)}
            return items
        }, [])
        if (!selected_category.items.length){
            state.catering_categories = state.catering_categories.filter(category => category.id !== selected_category.id);
        }
    },
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}
