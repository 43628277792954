<template>
  <ImageWithError
    :src="imageUrl"
    :alt="alt"
    :contain="contain"
    :lazySrc="lazySrc"
    :srcset="srcset"
    :sizes="sizes"
    :position="position"
    :transition="transition"
    @error="errorHandler"
    :height="height"
    :width="width"
    v-bind="$attrs"
  />
</template>
<script>
import errorimage from '@/assets/images/error.png'; //defualt error image
import VImg from 'vuetify/lib/components/VImg'; // v-img

const ImageWithError = VImg.extend({
  //extend v-img
  name: 'ImageWithError',
  methods: {
    onError() {
      // removed parent defualt console error by ovverride the onError
      // keept the error emit to sense the error and change src of image to error image
      this.$emit('error', this.src);
    },
  },
});

export default {
  name: 'eImg',
  components: { ImageWithError },
  created() {
    this.imageUrl = this.src ?? this.errorImageUrl;
  },
  props: {
    alt: String,
    contain: Boolean,
    src: {
      type: [String, Object],
      default: '',
    },

    lazySrc: String,
    srcset: String,
    sizes: String,
    position: {
      type: String,
      default: 'center center',
    },
    transition: {
      type: [Boolean, String],
      default: 'fade-transition',
    },
    height: String,
    width: String,
    errorImageUrl: {
      type: String,
      default: errorimage,
    },
  },
  data: () => ({
    imageUrl: '',
  }),
  methods: {
    errorHandler(url) {
      this.imageUrl = this.errorImageUrl;
    },
  },
  watch: {
    src: function (val) {
      this.imageUrl = val ?? this.errorImageUrl;
    },
  },
};
</script>
