export default {
  path: 'pos',
  component: { template: '<router-view></router-view>' },
  name: 'pos',
  meta: {
    plugin: 'pos',
  },
  children: [
    {
      path: 'pay/collect/:collector_id',
      name: 'pay-self-created-collector',
      meta: {
        plugin: 'pos',
      },
      component: () =>
        import(
          /* webpackChunkName: "PosCreate" */ '../../views/customer/pos/PosCreate'
        ),
    },
    {
      path: 'pay/service/:collector_id',
      name: 'pay-self-created-service',
      meta: {
        plugin: 'pos',
        type: 'service',
      },
      component: () =>
        import(
          /* webpackChunkName: "PosCreate" */ '../../views/customer/pos/PosCreate'
        ),
    },
    {
      path: 'pay/:transaction_id',
      name: 'pos-pay',
      meta: {
        plugin: 'pos',
      },
      component: () =>
        import(
          /* webpackChunkName: "PosPymtReq" */ '../../views/customer/pos/PymtReqPay'
        ),
    },
    {
      path: 'pay',
      name: 'pay-self-created',
      meta: {
        plugin: 'pos',
      },
      component: () =>
        import(
          /* webpackChunkName: "PosCreate" */ '../../views/customer/pos/PosCreate.vue'
        ),
    },
  ],
};
