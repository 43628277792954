const state = {
  renewalDate: '',
  sections: null,
  idleTime: null,
  idleCountdownTimer: 60,
};
const getters = {
  getRenewalDate: (state) => {
    return state.renewalDate;
  },
  getSections: (state) => {
    return state.sections;
  },
  getIdleTime: (state) => {
    return state.idleTime;
  },
  getIdleCountdownTimer: (state) => {
    return state.idleCountdownTimer;
  },
};

const actions = {
  setRenewalDate({ commit }, value) {
    commit('setRenewalDate', value);
  },
  setSections({ commit }, value) {
    commit('setSections', value);
  },
  setIdleTime({ commit }, value) {
    commit('setIdleTime', value);
  },
  setIdleCountdownTimer({ commit }, value) {
    commit('setIdleCountdownTimer', value);
  },
};

const mutations = {
  setRenewalDate(state, value) {
    state.renewalDate = value;
  },
  setSections(state, value) {
    state.sections = value;
  },
  setIdleTime(state, value) {
    state.idleTime = value;
  },
  setIdleCountdownTimer(state, value) {
    state.idleCountdownTimer = value;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
