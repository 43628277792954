import {PluginsSerice} from "./index";
import { BASE_API_URL } from "../index";
import Vue from 'vue'

export const PLUGIN_SUBPATH = {
  payment_request: 'payment_request',
  customer_payment: 'customer_payment',
  bulk: 'bulk',
  catalogue: 'catalogue',
  shopify: 'shopify',
  e_commerce: 'e_commerce',
  event: 'event',
  food_ordering:'food_ordering',
  session:'session',
  real_estate: 'real_estate'
};

export class TransactionsService {
  constructor(plugin_name){
    this.plugin_name = plugin_name;
    this.plugin_subpath = PLUGIN_SUBPATH[plugin_name];
    this.transaction_subpath = 'transaction';
    this.pluginService = PluginsSerice(`/${this.plugin_subpath}`)
  }

  getOptions(){
    return this.pluginService.options(`/${this.transaction_subpath}`);
  }
  getDetailsOptions(id){
    return this.pluginService.options(`/${this.transaction_subpath}/pay/${id}`)
  }
  getDetails(id){
    return this.pluginService.get(`/${this.transaction_subpath}/${id}`)
  }
  getList(params){
    return this.pluginService({
      url: `/${this.transaction_subpath}`,
      method: 'GET',
      params
    })
  }
  getHeaders(params){
      return this.pluginService({
      url: `/transaction/fields`,
      method: 'GET',
      params
    })
  }
  updateHeader(data){
    return this.pluginService({
      url: `/${this.transaction_subpath}/fields`,
      method: 'PATCH',
      data
    })
  }
  getExportConfig(){
    return this.pluginService.options(`/${this.transaction_subpath}/export`)
  }
  getExportPath(){
    return `${BASE_API_URL}/plugins/${this.plugin_subpath}/${this.transaction_subpath}/export`
  }
  getExportLink(params={}){
    params['auth_token'] = Vue.prototype.$token();
    const query_string = Object.keys(params)
      .map(k => `${encodeURIComponent(k)}=${encodeURIComponent(params[k])}`)
      .join('&');
    return `${this.getExportPath()}?${query_string}`
  }
  getStatistics(params){
    return this.pluginService({
      url: '/currency_statistics',
      method: 'GET',
      params
    })
  }
  getPayDetails(transaction_id){
    return this.pluginService.get(`/${this.transaction_subpath}/pay/${transaction_id}`)
  }
  updatePayDetails(data,transaction_id){
    return this.pluginService({
      url: `/${this.transaction_subpath}/partial-update/${transaction_id}`,
      method: 'PATCH',
      data
    })
  }
}

export class DeletedTransactionService extends TransactionsService {
  constructor(...args){
    super(...args);
    this.transaction_subpath = 'deleted_transaction'
  }
}

export class FullfilmentService extends TransactionsService {
  constructor(...args){
    super(...args);
    this.transaction_subpath = 'deleted_transaction'
  }
}
