import axios from "axios";

import { authorizedReq, BASE_API_URL, API_ORIGIN } from "./index";

export const getUserDetails = () =>
  authorizedReq.get(BASE_API_URL + "/user/details");

export const getMerchantConfig = () =>
  authorizedReq.get(BASE_API_URL + "/config/");

export const getSectionsConfig = () =>
  authorizedReq.get(BASE_API_URL + "/plugins/sections/");

export const loadTranslations = () => axios.get(`${API_ORIGIN}/json-i18n`);
