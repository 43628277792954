import Vue from 'vue';
import singleSpaVue from 'single-spa-vue';
import App from './App.vue';
import moment from 'moment';
import * as Sentry from "@sentry/browser";
import pjson from "../package.json";
import * as Integrations from "@sentry/integrations";
import { i18n } from './plugins/i18n'
import router from './router'
import store from './store'

Vue.config.productionTip = false

const vueLifecycles = singleSpaVue({
  Vue,
  appOptions: {
    render(h) {
      return h(App, {
        props: {}
      })
    },
    created(){
      Vue.prototype.$token = this.idToken
      Vue.prototype.$logout = this.logout
      Vue.prototype.$noNavBar = this.noNavBar
      Vue.prototype.$orderRefreshTime = this.orderRefreshTime
      Vue.prototype.$foodOrderingColor = this.foodOrderingColor

      const lang = this.$route.path.slice(1, 3)

      if (lang === 'en' || lang === 'ar') {
        this.$i18n.locale = lang
      }

      this.setLang()
      moment.locale(this.$i18n.locale);
      this.$vuetify.rtl = this.$i18n.locale==='ar';

      Sentry.setTag("microfrontend", "fnb_frontend");

      Sentry.init({
        dsn: this.sentryUrl,
        integrations: [new Integrations.Vue({Vue, attachProps: true, logErrors: true})],
        release: `fnb_frontend@${pjson.version}`,
        // tracesSampleRate: this.sentrySampleRate,
        sampleRate: this.sentrySampleRate,
        ignoreErrors: this.ignoredSentryErrors,
        beforeSend : this.checkSentryErrors,
        autoSessionTracking: false
      });

    },
    watch: {
      '$i18n.locale': function (lang, old_lang) {
        this.$vuetify.rtl = this.$i18n.locale==='ar';
        moment.locale(this.$i18n.locale)
      }
    },
    methods: {
      setLang() {
        const translations = this.translations()
        this.$i18n.setLocaleMessage('en', translations.en);
        this.$i18n.setLocaleMessage('ar', this.arabicTranslation);
      }
    },
    i18n,
    router,
    store
  }
})

export const bootstrap = vueLifecycles.bootstrap;
export const mount = vueLifecycles.mount;
export const unmount = vueLifecycles.unmount;
