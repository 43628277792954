<template>
  <v-dialog
    v-model="showModal"
    width="518"
    persistent
    class="session-modal"
    margin="auto"
  >
    <v-card class="text-center">
      <v-card-title
        class="oo-dialog-title justify-center headline session-modal-title"
        style="
          text-transform: capitalize;
          position: relative;
          padding: 10px !important;
        "
      >
        {{ $t('Careful...') }}
      </v-card-title>
      <div class="dialog-title justify-center" style="text-align: center">
        <p>{{ $t('The session expires in ') }} {{ countDownTimer }}</p>
      </div>
      <v-card-text
        style="
          height: 40px;
          display: flex;
          align-items: center;
          justify-content: center;
        "
      >
        <p class="dialog-text">
          {{
            $t(
              `If you are still using ottu, press the "Wait, I'm not done yet" button, otherwise you will be disconnected.`,
            )
          }}
        </p>
      </v-card-text>
      <v-card-actions class="justify-center dialog-buttons text-capitalize">
        <v-btn
          class="confirm-btn btn-refund"
          color="refund-btn"
          text
          :loading="loading"
          @click="refreshTokenManually"
        >
          {{ $t("Wait, I'm not done yet") }}
        </v-btn>
        <v-btn
          class="confirm-btn btn-refund"
          color="refund-btn"
          @click="logout"
        >
          {{ $t('Logout') }}
        </v-btn>
      </v-card-actions>
    </v-card>
  </v-dialog>
</template>

<script>
import Vue from 'vue';

import { isLoggedIn, parseJwt } from '../lib/auth';
import store from '../store';

export default {
  name: 'SessionModal',
  props: {
    isIdle: Boolean,
  },
  data: () => ({
    showModal: false,
    loading: false,
  }),
  created: function () {
    this.InitTokenRefresh();
  },
  methods: {
    InitTokenRefresh() {
      if (!Vue.prototype.$token()) return; // if non auth pages exit
      // as long as user not idle keep refreshing token
      // if enableSessionTimeout is false logout on idle and idle time equal to 24h
      // if enableSessionTimeout is ture and user detected idle in (VUE_APP_SESSION_IDLE) time//token expiry time
      // then the popup will ask user if he still working with timer count down
      // clicking resume will update token using refresh token
      // or he may logout. if timer runs out he will be loged out
      let interrvalTime =
        localStorage.getItem('VUE_APP_SESSION_IDLE') || 1 * 60 * 60 * 1000;
      if (!Vue.prototype.$isKeycloakAuth) {
        const exp = parseJwt(Vue.prototype.$token()).exp;
        interrvalTime =
          (exp - 60 - Date.now() / 1000) * 1000 || 1 * 60 * 60 * 1000;
      }

      if (Vue.prototype.$sessionStateWorkerInstance) {
        Vue.prototype.$sessionStateWorkerInstance.sendMessage({
          action: 'startRefreshInterval',
          refreshIntervalTime: interrvalTime,
        });

        window.addEventListener('beforeunload', () => {
          Vue.prototype.$sessionStateWorkerInstance.sendMessage({
            action: 'stopRefreshInterval',
          });
        });
      }
    },
    startIdleWorker() {
      if (Vue.prototype.$sessionStateWorkerInstance) {
        Vue.prototype.$sessionStateWorkerInstance.sendMessage({
          action: 'startIdleCountdown',
          countdown: this.countDownTimer,
        });
      }
    },
    stopWorkerCountdown() {
      if (Vue.prototype.$sessionStateWorkerInstance) {
        Vue.prototype.$sessionStateWorkerInstance.sendMessage({
          action: 'stopIdleCountdown',
        });
      }
    },
    async refreshTokenManually() {
      this.loading = true;
      this.stopWorkerCountdown();
      try {
        if (Vue.prototype.$isKeycloakAuth) {
          await Vue.prototype.$refreshToken();
        } else {
          await this.$processReqSilent(
            Vue.prototype.$refreshToken(localStorage.getItem('refresh')),
          );
        }
      } catch (err) {
        Vue.prototype.$sessionStateWorkerInstance.logoutAcrossTabs();
      }

      this.loading = false;
      this.resetToDefaultValue();
    },
    resetToDefaultValue() {
      this.showModal = false;
    },
    logout() {
      this.resetToDefaultValue();
      Vue.prototype.$sessionStateWorkerInstance.logoutAcrossTabs();
    },
    checkSession() {
      if (this.showModal) {
        return;
      }

      this.showModal = true;
      this.startIdleWorker();
    },
  },
  computed: {
    countDownTimer() {
      return store.getters['dashboard/getIdleCountdownTimer'];
    },
  },
  watch: {
    isIdle: async function (newVal, oldVal) {
      if (!Vue.prototype.$enableSessionTimeout || !Vue.prototype.$token())
        return;
      if (newVal !== oldVal && isLoggedIn()) {
        this.checkSession();
      }
    },
    countDownTimer() {
      if (this.countDownTimer === 60) {
        this.resetToDefaultValue();
      }
    },
  },
};
</script>

<style scoped>
.session-modal {
  max-height: 364px !important;
}
.btn-refund {
  background-color: #f77c1a !important;
}
.session-modal-title {
  max-width: 100% !important;
  background-color: #f77c1a !important;
}
.v-card__title {
  color: white;
  font-size: 35px !important;
}
.dialog-title {
  font-size: 25px;
}
.btn-refund {
  font-size: 14px;
  padding: 0 12px;
}
.dialog-text {
  font-size: 16px;
}
</style>
