import { i18n } from '../../plugins/i18n';

export default {
  path: 'e-commerce',
  component: {
    template: `<router-view></router-view>`,
  },
  name: 'e-commerce',
  meta: {
    page_title: 'E-commerce',
    is_wrapper: true,
  },
  children: [
    {
      path: 'transactions',
      name: 'e-commerce-transactions',
      component: () =>
        import(
          /* webpackChunkName: "ECommerce" */ '../../views/dashboard/e_commerce/Transactions'
        ),
      meta: {
        page_title: i18n.tc('Transactions'),
        plugin: 'e_commerce',
        breadcrumb_items: [
          'general-info',
          'e-commerce',
          'e-commerce-transactions',
        ],
      },
    },
    {
      path: 'transactions/:id',
      name: 'e-commerce-transactions-details',
      component: () =>
        import(
          /* webpackChunkName: "ECommerce" */ '../../views/dashboard/e_commerce/TransactionDetails'
        ),
      meta: {
        plugin: 'e_commerce',
      },
    },
    {
      path: 'deleted-transactions',
      name: 'e-commerce-deleted',
      component: () =>
        import(
          /* webpackChunkName: "ECommerce" */ '../../views/dashboard/e_commerce/DeletedTransactions'
        ),
      meta: {
        page_title: i18n.tc('Deleted Paid Transactions'),
        breadcrumb_items: ['general-info', 'e-commerce', 'e-commerce-deleted'],
        plugin: 'e_commerce',
      },
    },
    {
      path: 'deleted-transactions/:id',
      name: 'e-commerce-deleted-details',
      component: () =>
        import(
          /* webpackChunkName: "ECommerce" */ '../../views/dashboard/e_commerce/DeletedTransactionDetails'
        ),
      meta: {
        plugin: 'e_commerce',
      },
    },
    {
      path: 'table-headers',
      name: 'e-commerce-settings-table-headers',
      component: () =>
        import(
          /* webpackChunkName: "TableHeaders" */ '../../views/dashboard/e_commerce/_settings/TableHeaders.js'
        ),
      meta: {
        page_title: i18n.tc('Table Headers'),
        breadcrumb_items: ['general-info', 'e-commerce', 'table-headers'],
      },
    },
    {
      path: 'invoice',
      name: 'e-commerce-create-invoice',
      component: () =>
        import(
          /* webpackChunkName: "InvoiceCreate" */ '../../views/dashboard/e_commerce/CreateInvoice'
        ),
      meta: {
        page_title: i18n.tc('Create Invoice'),
        breadcrumb_items: [
          'general-info',
          'e-commerce',
          'e-commerce-create-invoice',
        ],
      },
    },
    {
      path: 'invoices/:id',
      name: 'e-commerce-view-invoice',
      component: () =>
        import(
          /* webpackChunkName: "Invoice" */ '../../views/dashboard/e_commerce/CreateInvoice'
        ),
      meta: {
        page_title: i18n.tc('Invoice'),
        breadcrumb_items: [
          'general-info',
          'e-commerce',
          'e-commerce-create-invoice',
        ],
      },
    },
    {
      path: 'deleted-invoices/:id',
      name: 'e-commerce-deleted-invoices',
      component: () =>
        import(
          /* webpackChunkName: "DeletedInvoice" */ '../../views/dashboard/e_commerce/CreateInvoice'
        ),
      meta: {
        page_title: i18n.tc('Deleted Invoice'),
        breadcrumb_items: [
          'general-info',
          'e-commerce',
          'e-commerce-deleted-invoices',
        ],
      },
    },
  ],
};
