<template>
  <v-snackbar v-model="show" :color="color">
    {{ text }}
    <a v-if="link" :href="link" target="_blank" @click="snackbarClick">{{
      $t('Download')
    }}</a>
    <v-btn flat dark @click="snackbarClick">{{ getText }}</v-btn>
  </v-snackbar>
</template>

<script>
export default {
  name: 'Snackbar',
  data: () => ({
    show: false,
    text: '',
    color: 'info',
    callback: Function,
    data: {},
    link: null,
  }),
  methods: {
    snackbarClick() {
      if (this.callback) {
        this.callback(this.data);
        this.show = false;
      } else this.show = false;
    },
  },
  computed: {
    getText() {
      return this.callback == undefined ? 'Close' : 'Go';
    },
  },
  created() {
    this.$root.$on(
      'snackbar',
      ({ text, color = 'info', calback, data, link }) => {
        this.text = text;
        this.color = color;
        this.show = true;
        this.callback = calback;
        this.data = data;
        this.link = link;
      },
    );
  },
};
</script>

<style scoped></style>
