export default {
  path: 'user',
  name: 'user',
  component: { template: '<router-view></router-view>' },
  meta: {
    page_title: 'User',
    is_wrapper: true,
  },
  children: [
    {
      path: '',
      name: 'user-details',
      component: () =>
        import(
          /* webpackChunkName: "UserDetails" */ '../../views/dashboard/UserDetails'
        ),
      meta: {
        page_title: 'Details',
        breadcrumb_items: ['user', 'user-details'],
      },
    },
    {
      path: 'password',
      name: 'password',
      component: () =>
        import(
          /* webpackChunkName: "ChangePassword" */ '../../views/dashboard/PasswordChange'
        ),
      meta: {
        page_title: 'Change password',
        breadcrumb_items: ['user', 'password'],
      },
    },
  ],
};
