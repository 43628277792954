<template>
  <v-app :class="classes" id="app_frontend">
    <router-view></router-view>
    <Snackbar />
    <Version />
  </v-app>
</template>

<script>
import Vue from 'vue';
import Snackbar from "./components/Snackbar";
import Version from "./components/Version";

export default {
  name: 'App',
  components: {
    Snackbar,
    Version
  },
  computed: {
    classes() {
      return !Vue.prototype.$noNavBar && 'navbarShow p-b-50 p-t-90' 
    }
  }
}
</script>

<style scoped>
  .p-b-50 {
    padding-bottom: 50px;
  }
  .p-t-90 {
    padding-top: 90px;
  }
  .navbarShow {
    padding-left: 300px;
  }

  .application--is-rtl.navbarShow{
    padding-left: 0px;
    padding-right: 300px;
  }
  @media screen and (max-width: 786px) {
    .navbarShow {
      padding-left: 0px;
    }
    .application--is-rtl.navbarShow{
      padding-left: 0px;
      padding-right: 0px;
    }
  }
</style>
