<template>
        <v-container>
            <TableLoader v-if="tableLoading"/>
            <v-layout column v-if="!tableLoading">
                    <template v-slot:header>
                        <span class="mr-2">{{$t('rows per page')}}:</span>
                        <v-radio-group v-model="per_page" row>
                            <v-radio v-if="total_rows_count>10" label="10" :value="10"/>
                            <v-radio v-if="total_rows_count>50" label="50" :value="50"/>
                            <v-radio v-if="total_rows_count>100" label="100" :value="100"/>
                            <v-radio :label="$t('all')" :value="total_rows_count"/>
                        </v-radio-group>
                    </template>
                    <v-data-table
                        :headers="branchesTableHeader"
                        :items="branches"
                        hide-actions
                        class="table-wrap px-0"
                    >
                        <template v-slot:items="props">
                            <td class="text-xs-left">
                                <router-link :to="$route.path+'/'+props.item.id">{{props.item['name']}}</router-link>
                            </td>
                            <td class="text-xs-left">
                                {{props.item['email']}}
                            </td>
                            <td class="text-xs-left">
                                {{props.item['phone']}}
                            </td>
                            <td class="text-xs-left">
                                <div v-html="props.item['address']"></div>
                            </td>
                        </template>
                    </v-data-table>
            </v-layout>
        </v-container>
</template>

<script>
    import food_ordering from "../../../services/plugins/food_ordering";
    import BaseList from "../../dashboard/BaseList";
    import TableLoader from "../../loaders/TableLoader";

    const ITEMS_PER_PAGE = 10;

    export default {
        name: "BranchList",
        components: {TableLoader},
        mixins: [BaseList],
        data: () => ({
            tableLoading:true,
            branches: [],
            total_rows_count: 0,
            table_data: [],
        }),
        methods: {
            async getBranchesList(){
                this.tableLoading=true;
                try {
                    const res = await food_ordering.getBranchesList()
                    this.tableLoading=false
                    this.branches = res.data.results;
                    this.total_rows_count = res.data.count;
                    this.pages_count = Math.ceil(res.data.count/ITEMS_PER_PAGE);
                } catch (error) {
                    console.log('Error: Unable to get Branches list - ', error);
                }
            }
        },
        computed: {
            per_page: {
                get: function(){
                    return parseInt(this.$route.query.per_page) || 10
                },
                set: function(value){
                    this.$router.push({query: {...this.$route.query, per_page: value}}).catch(err => {
                        console.log(err)
                    })
                }
            },
            branchesTableHeader(){
                return [
                    { text: this.$t('Branch name'), value: 'name', sortable: false },
                    { text: this.$t('Email'), value: 'email', sortable: false },
                    { text: this.$t('Phone'), value: 'phone', sortable: false },
                    { text: this.$t('Address'), value: 'address', sortable: false },
                ]
            }
        },
        created() {
            this.getBranchesList();
        }
    }
</script>

<style scoped>

</style>
