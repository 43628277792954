<template>
  <v-snackbar
    v-model="show"
    :color="color"
  >
    {{ text }}
    <v-btn
      dark
      flat
      @click="show = false"
    >Close</v-btn>
  </v-snackbar>
</template>

<script>
  export default {
    name: "Snackbar",
    data: () => ({
      show: false,
      text: '',
      color: 'info'
    }),
    created() {
      this.$root.$on('snackbar', ({text, color='info'}) => {
          this.text = text;
          this.color = color === "success" ? "success-color" : color;
          this.show = true;
      })
    }
  }


</script>

<style scoped>
.success-color {
  background-color: #4caf50 !important;
  border-color: #4caf50 !important;
}
</style>
