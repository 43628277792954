import { AuthorizedService } from '../index';

const baseTicket = '/plugins/c/oa/';
const BaseTicketService = (url) => AuthorizedService(url);
class RefundVoidRequestTicketService {
  constructor() {
    this.ticketService = BaseTicketService(baseTicket);
    this.process = 'processes/operation/';
    this.tasks = 'tasks/operation/';
  }
  getDetails(id) {
    return this.ticketService.get(
      `/${this.tasks}?process_id=${id}&task_list=ACTIVE`,
    );
  }
  getList(params) {
    return this.ticketService({
      url: `/${this.process}`,
      method: 'GET',
      params,
    });
  }
  getCount() {
    return this.ticketService({
      url: '/counter',
      method: 'GET',
    });
  }
  getListMetadata() {
    return this.ticketService({
      url: `/${this.process}`,
      method: 'OPTIONS',
    });
  }
  CallAction(url, payload) {
    return this.ticketService({
      baseURL: url,
      method: 'Post',
      data: payload,
    });
  }
}

export default new RefundVoidRequestTicketService();
