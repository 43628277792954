export default {
  methods: {
    switchLanguage() {
      const lang = this.$i18n.locale === 'ar' ? 'ar' : 'en';
      const target_lang = this.$i18n.locale === 'en' ? 'ar' : 'en';
      this.$i18n.locale = target_lang;
      const path = this.$route.fullPath.replaceAll(
        `/${lang}/`,
        `/${target_lang}/`,
      );
      this.$router.push({ path: path });
    },
    localePath(path) {
      return `/${this.$i18n.locale}${path}`;
    },
  },
};
