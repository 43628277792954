<template>
  <v-dialog
    v-model="history_dialog"
    width="700"
  >
    <template v-slot:activator="{ on }">
      <v-btn fab small outline v-on="on">
        <v-icon>history</v-icon>
      </v-btn>
    </template>
    <v-card>
      <v-card-title>
        <b class="headline">Activity history</b>
        <v-spacer></v-spacer>
        <v-btn icon @click="history_dialog=false"><v-icon>clear</v-icon></v-btn>
      </v-card-title>
      <v-list two-line>
        <v-list-tile v-for="(action,index) in actions" v-bind:key="`action-${index}`">
          <v-list-tile-content>
            <v-list-tile-title>{{action.actor}}</v-list-tile-title>
            <v-list-tile-sub-title>{{action.description}}</v-list-tile-sub-title>
          </v-list-tile-content>
          <v-list-tile-action>
            {{action.timestamp}}
          </v-list-tile-action>
        </v-list-tile>
      </v-list>
    </v-card>
  </v-dialog>
</template>

<script>
  import {getUserActions} from "../services/common";

  export default {
    name: "HistoryDialog",
    data: () => ({
      history_dialog: false,
      actions: []
    }),
    methods: {
      async loadHistory(){
        try {
          const res = await this.$processReq(getUserActions(), false)
          this.actions = res.data.results
          return res
        } catch (error) {
            console.log('Error: Unable to get user actions - ', error);
        }
      }
    },
    watch: {
      history_dialog: function (new_value) {
        if (new_value===true){
          this.loadHistory()
        }
      }
    }
  }
</script>

<style scoped>

</style>
