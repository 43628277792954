export const ignoredSentryErrors = [
  "Network Error",
  "timeout of 0ms exceeded",
  "Non-Error promise rejection captured with keys: currentTarget, isTrusted, target, type",
  "Loading chunk",
  "Unexpected token '<'",
  /^application '@ottu-mf\/(navigation|frontend|fnb_frontend)' died in status LOADING_SOURCE_CODE/,
  /^application '@ottu-mf\/(navigation|frontend|fnb_frontend)' died in status LOADING_SOURCE_CODE: undefined is not an object \(evaluating 'n.bootstrap'\)/,
];

export const SINGLE_SPA_CSS_TIMEOUT_ERROR_MESSAGE_REGEX =
  /"single-spa-css: While mounting '.*', loading CSS from URL (https?:\/\/[^ ]+) timed out after \d+ms"/;

export const MERCHANT_ADMIN_ROLE = "Merchantadmin";

export const PRIMARY_IMPORT_MAP = {
  imports: {
    "single-spa":
      "https://fastly.jsdelivr.net/npm/single-spa@5.9.5/lib/system/single-spa.min.js",
    vue: "https://fastly.jsdelivr.net/npm/vue@2.7.16/dist/vue.min.js",
    "vue-router":
      "https://fastly.jsdelivr.net/npm/vue-router@3.6.5/dist/vue-router.min.js",
  },
};

export const FALLBACK_IMPORT_MAP = {
  imports: {
    "single-spa":
      "https://cdnjs.cloudflare.com/ajax/libs/single-spa/5.9.5/system/single-spa.min.js",
    vue: "https://cdnjs.cloudflare.com/ajax/libs/vue/2.7.16/vue.min.js",
    "vue-router":
      "https://cdnjs.cloudflare.com/ajax/libs/vue-router/3.6.5/vue-router.min.js",
  },
};

export const PRIMARY_CDN_PACKAGES = [
  "https://fastly.jsdelivr.net/npm/systemjs/dist/system.js",
  "https://fastly.jsdelivr.net/npm/systemjs/dist/extras/amd.js",
];

export const FALLBACK_CDN_PACKAGES = [
  "https://cdnjs.cloudflare.com/ajax/libs/systemjs/6.15.1/system.js",
  "https://cdnjs.cloudflare.com/ajax/libs/systemjs/6.15.1/extras/amd.min.js",
];
