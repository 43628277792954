import ProductBulkCreate from "../../views/dashboard/food_ordering/ProductBulkCreate";
import BranchList from "../../views/dashboard/food_ordering/BranchList";
import BranchDetails from "../../views/dashboard/food_ordering/BranchDetails";
import OrderFullfilment from "@/views/dashboard/food_ordering/OrderFulfillment";

export default {
  path: 'food-ordering',
  component: {
    template: `<router-view></router-view>`
  },
  name: 'food-ordering',
  meta: {
    page_title: 'Food ordering',
    is_wrapper: true,
    requiresAuth: true,
  },
  children: [
    {
      path: 'orders',
      name: 'orders',
      component: () => import(/* webpackChunkName: "order-list" */"../../views/dashboard/food_ordering/OrdersList")
    },
    {
      path: 'orders/:id',
      name: 'order-details',
      component: () => import(/* webpackChunkName: "order-details" */"../../views/dashboard/food_ordering/OrderDetails")
    },
    {
      path: 'orders/:id/duplicate',
      name: 'order-details-duplicate',
      component: () => import(/* webpackChunkName: "order-details" */"../../views/dashboard/food_ordering/OrderDetailsDuplicated"),
      meta: {
        hide_navbar: true
      }
    },
    {
      path: 'bulk-create',
      name: 'product-bulk-create',
      component: ProductBulkCreate
    },
    {
      path: 'branches',
      name: 'branches-list',
      component: BranchList
    },
    {
      path: 'branches/:id',
      name: 'branch-details',
      component: BranchDetails
    },
    {
      path: 'fulfillment',
      name: 'order-fulfillment',
      component: OrderFullfilment,
      meta: {
        page_title: 'Fulfillment',
        breadcrumb_items: ['general-info', 'food-ordering', 'order-fulfillment'],
        plugin: 'event'
      }
    }
  ]
}
