import {i18n} from "../../plugins/i18n";
import router from '../../router/index'

const state = {
    items:[],
    delivery_type: 'delivery',
    branch_working_hour:undefined,
    delivery_working_hour:undefined,
    header_configs:{},
    location_choices:{},
    location:"",
    delivery_areas_configs:{},
    menu_config:{},
    is_closed:false,
    search:'',
    selected_category:'',
    category_items:[],
    catering_date:'',
    voucher: '',
    showClearCartModal:false,
    showClearCartModalforType:false
  };

  if(localStorage.getItem('category_cart')) {
    state.category_items =  JSON.parse(localStorage.getItem('category_cart'));
  }

  if (localStorage.getItem('delivery_type')){
      state.delivery_type = localStorage.getItem('delivery_type');
  }

  if (localStorage.getItem('location')){
      state.location = parseInt(localStorage.getItem('location'));
  }

  function arraysEqual(a, b) {    
    if (a === b) return true;
    if (a == null || b == null) return false;
    if (a.length !== b.length) return false;
  
    // If you don't care about the order of the elements inside
    // the array, you should sort both arrays here.
    // Please note that calling sort on an array will modify that array.
    // you might want to clone your array first.
    a = a.map(e => e.id).sort()
    b = b.map(e => e.id).sort()
  
    for (var i = 0; i < a.length; ++i) {
      if (a[i] !== b[i]) return false;
    }
    return true;
  }

  function isAddOnsEquals(arr1, arr2) {
    if (arr1.length !== arr2.length)
        return false;

    let isEqual = true;
    arr1 = arr1.map(a => a.id).sort();
    arr2 = arr2.map(a => a.id).sort();

    for (let i = 0; i < arr1.length; i++){
        if (arr1[i] !== arr2[i]){
            isEqual = false;
        }
    }

    return isEqual
}

function isOptionsEquals(arr1, arr2) {
   if (Object.keys(arr1).length !== Object.keys(arr2).length)
        return false;

    let isEqual = true;
    Object.keys(arr1).forEach(index =>{
      if(Array.isArray(arr2[index])){
        //if(Array.isArray(arr1[index])){
          if( !arraysEqual(arr1[index],arr2[index])){
            isEqual = false
          }
        // } else
        // isEqual = false;
        
      }
      else if(!arr2[index] || arr1[index].id !== arr2[index].id){
          isEqual = false;
      }
    })
    return isEqual
}

function notInCart(cartItems, item) {
  
    let result = undefined;
    cartItems.forEach(cartItem => {
        if (cartItem.details.id == item.product.id && isAddOnsEquals(cartItem.addOns, item.addOns) && isOptionsEquals(cartItem.options?cartItem.options:[],item.options?item.options:[]) && cartItem.special_instruction === item.special_instruction && cartItem.size === item.size){
            result = cartItem;
        }
    })
    return result;
}
  
  const getters = {
    getVoucher: (state) => {
      return state.voucher
    },
    branchWorkingHours:(state) =>{
        if(state.delivery_type == 'pickup')
            return state.branch_working_hour
        else
            return state.delivery_working_hour
    },
    location_list:(state) => {
      if(state.delivery_type == 'pickup')
            return state.location_choices.branch_choices
        else if(state.delivery_type == 'catering')
            return state.location_choices.delivery_areas_choices//.filter(e => e.is_enabled_for_catering)
        else
          return state.location_choices.delivery_areas_choices
    },
    cartProducts: (state) => {
        return state.items.map(({ details, quantity, uId, addOns,options }) => {
            let price = details.price_with_discount?details.price_with_discount:details.product.price
            //let total = price * quantity
            // if (details.addOns.length){
            //     total += details.addOns.reduce((sum, addOn) => {
            //         return sum + parseFloat(addOn.price) * quantity
            //     }, 0);
            // }
            let addOnSum = addOns.reduce((sum, addOn) => {
                return sum + parseFloat(addOn.price)
            }, 0);
            return {
                id: details.id,
                name: details.product.name[i18n.locale],
                price: details.product.price,
                price_with_addons: parseFloat(price) + addOnSum,
                quantity,
                addOns,
                options,
                images: details.product.product_images,
                product: details.product.id,
                total: (parseFloat(price)+ addOnSum) * quantity,
                price_with_discount: details.price_with_discount,
                special_instruction: details.special_instruction,
                uId
            }
        })
    },
    cateringCartProducts: (state) => {
      return state.category_items.map(category => {
          let items = category.items.map(({ details, quantity, uId, addOns,options,size, special_instruction }) => {
            let price = details.price_with_discount ? details.price_with_discount : details.price;
            let addOnSum = addOns.reduce((sum, addOn) => {
                return sum + parseFloat(addOn.price)
            }, 0);
            return {
                id: details.id,
                name: details.name[i18n.locale],
                price: details.price,
                price_with_addons: parseFloat(price) + addOnSum,
                quantity,
                addOns,
                options,
                options_choices:details.options,
                size,
                images: details.product_images,
                //product: details.id,
                originalPrice: details.price_with_discount ? (parseFloat(details.price) + addOnSum) * quantity : null,
                currentPrice: (parseFloat(price) + addOnSum) * quantity,
                price_with_discount: details.price_with_discount ? details.price_with_discount : null,
                special_instruction,
                uId
            }
          })
          return {
              id: category.id,
              name: category.name,
              minimum_number_of_plates: category.minimum_number_of_plates,
              number_of_plates: category.number_of_plates,
              service_charges: parseFloat(category.service_charges),
              items: items
          }
      })
    },
    cartTotalPrice: (state, getters) => {
        let sum = getters.cateringCartProducts.reduce((total, category) => {
          
          let categoryTotal = category.items.reduce((catTotal,item)=>{
            if(state.delivery_type == 'catering')
              return catTotal + item.price_with_addons * category.number_of_plates
            else if(item.originalPrice)
              return catTotal + item.originalPrice
            else
              return catTotal + item.currentPrice
          },0)
          return total + categoryTotal
        },0)
        return sum;
    },
    cartNetPrice: (state, getters) => {
      let sum = getters.cateringCartProducts.reduce((total, category) => {
        
        let categoryTotal = category.items.reduce((catTotal,item)=>{
          if(state.delivery_type == 'catering')
            return catTotal + item.price_with_addons * category.number_of_plates
          else
            return catTotal + item.currentPrice
        },0)
          return total + categoryTotal
      },0)
      return sum;
  },
    discountTotalPrice: (state, getters) => {
      let sum = getters.cateringCartProducts.reduce((total, category) => {
        let categoryTotal = category.items.reduce((catTotal, item)=>{
            if(item.originalPrice){
              return catTotal + item.originalPrice - item.currentPrice
            }else{
              return catTotal;
            }
        },0)
          return total + categoryTotal
      },0)
      return sum;
  },
    getCateringServiceCharge: (state, getters) => {
      let charge = getters.cateringCartProducts.reduce((total, category) => {
          return total + parseFloat(category.service_charges)
      }, 0);
      
      return charge;
    },
    getProductsLength: (state) => {
      let length = state.category_items.reduce((total, category) => {
          return total + category.items.length
      }, 0);
      
      return length;
    },
    menuConfigs:(state) => {
      return state.menu_config
    },
    search:(state) => {
        return state.search
      },
    getCheckoutItems: () =>{
        return state.category_items.map((category) => {
            let items = category.items.map(item => item.id);
            if(items.options){
                Object.keys(items.options).map((key) => {
                    items.options[key] =  items.options[key].id;
                  });
            }
            return {
                category: category.id,
                number_of_plates: category.number_of_plates,
                menu_items: items
            }
        })
    },
     checkoutItems: (state) => {
        let checkoutItems = []
        state.category_items.map(({items}) => {
          items.map(({ details, quantity, addOns, options,size,special_instruction,item_id }) => {
            let addOnsIds = addOns.map(addOn => addOn.id);
            let option = {}
            if(options && Object.keys(options).length > 0)
                Object.keys(options).map((key) => {
                  if(Array.isArray(options[key])){
                    //option arraay checsafkasf
                    option[key] = options[key].map(e => e.id)
                  }else
                    option[key] =  [options[key].id];
                });
                checkoutItems.push(
                   {
                    menu_item: item_id,
                    special_instructions: special_instruction,
                    quantity,
                    add_ons: addOnsIds,
                    options: option,
                    size
                })
          
          })
            
        })
        return checkoutItems
      },
      cateringCheckoutItems: (state) => {
        let checkoutItems = []
        state.category_items.map(({items,number_of_plates,id}) => {
          items.map(({ details, addOns, options,size,special_instruction,item_id }) => {
            let addOnsIds = addOns.map(addOn => addOn.id);
            let option = {}
            if(options && Object.keys(options).length > 0)
                Object.keys(options).map((key) => {
                    option[key] =  options[key].id;
                });
                checkoutItems.push(
                   {
                    menu_item: item_id,
                    category_id:id,
                    special_instructions: special_instruction,
                    quantity:number_of_plates,
                    add_ons: addOnsIds,
                    options: option,
                    size
                })
          
          })
            
        })
        return checkoutItems
      },
      getLocationName: (state,getters) => location_id => {
        if(getters.location_list && getters.location_list.find(e => e.value == location_id)) {
          return getters.location_list?getters.location_list.find(e => e.value == location_id).display_name:''
        } else { 
          return '';
        }
      }
    };

  const actions = {
    setMenuConfigs({ commit }, value){
      commit('setMenuConfigs', value);
    },
    setVoucher({commit}, value){
      commit('setVoucher', value)
    },
    setDeliveryType({ commit }, value){
        commit('setDeliveryType', value);
    },
    setHeaderConfigs({commit}, value){
      commit('setHeaderConfigs',value)
    },
    setLocationChoices({commit}, value){
      commit('setLocationChoices',value)
    },
    setDeliveryAreaConfigs({commit}, value){
      commit('setDeliveryAreaConfigs',value)
    },
    setLocation({commit}, value){
      commit('setLocation',value)
    }, 
    setSelectedCategory({commit}, value){
      commit('setSelectedCategory',value)
    },
    addProductToCart({commit},item){
      const cartItems = state.items.filter(prod => prod.id === item.id);
      if (!cartItems.length) {
          commit('pushProductToCart', { details: item })
      } else {
          let cartItem = notInCart(cartItems, item);
          if (!cartItem) {
              commit('pushProductToCart', { details: item })
          }
          else{
              commit('addAdditionalQty', {uId: cartItem.uId, quantity: item.quantity})
          }
      }
    },
    addProductToCategoryCart({commit},item){
        const cartCategorie = state.category_items.find(category => category.id === item.category.id);
        if (!cartCategorie){
            commit('addCateringCategoryToCart', {details: item})
        }
        else{
          let cartItem = notInCart(cartCategorie.items, item);
          if (!cartItem) {
            commit('addItemToCateringCategory', {details: item})
          }
          else{
              commit('addCateringAdditionalQty', {category_id: item.category.id, uId: cartItem.uId, quantity: item.quantity})
          }
            
        }
        commit('saveCart')
    },
    removeProductFromCart({commit},value){
      commit('removeProductFromCart',value)

      commit('saveCart')
    },
    incrementItemQuantity({state, commit}, value){
      if(state.delivery_type == 'catering')
        commit('incrementPlatesAmount',value)
      else
        commit('incrementItemQuantity', value);
      
        commit('saveCart')
    },
    decrementItemQuantity ({state, commit},  value ){
        if(state.delivery_type == 'catering')
          commit('decrementPlatesAmount',value)
        else
        commit('decrementItemQuantity', value);
      
        commit('saveCart')
    },
    updateSearch ({commit},  value ){
        commit('updateSearch', value);
    },
    setCateringDate({commit}, value){
      commit('setCateringDate',value)
    },
    clearAllCart({commit}){
      commit('clearAllCart')
    },
    closeClearCartModal({commit},value){
        commit('closeClearCartModal',value)     
    },
    closeClearCartModalforType({commit},value){
      commit('closeClearCartModalforType',value)     
    },
    clearAllData({commit}){
      commit('clearAllData')
    }
  }
  
  const mutations = {
    setVoucher(state, value){
      state.voucher = value;
    },
    setMenuConfigs(state,value){
      state.menu_config = value
    },
    setSelectedCategory(state,value){

    },
    setDeliveryType(state, value){
        if(state.delivery_type != value){

          let length = state.category_items.reduce((total, category) => {
                return total + category.items.length
            }, 0);
            if(length){
              state.showClearCartModalforType = !state.showClearCartModalforType
              state.delivery_type = value;
            } else{
              state.category_items = []
              state.location = ''
              localStorage.setItem('location', '');
              localStorage.removeItem('selectedCategory')
              localStorage.setItem('delivery_type', value);
              state.delivery_type = value;
              router.push({name:'new-menu'}).catch(err => {
                console.log(err)
              })
            }
            
        }   
    },
    closeClearCartModalforType(state,{status}){
      if(status){
        state.category_items = []
        state.location = ''
        localStorage.setItem('location', '');
        localStorage.removeItem('selectedCategory')
        state.showClearCartModalforType = false
        localStorage.removeItem('category_cart')
        localStorage.setItem('delivery_type', state.delivery_type);
        router.push({name:'new-menu'}).catch(err => {
          console.log(err)
        })
      } else {
        state.delivery_type = localStorage.getItem('delivery_type')
        state.showClearCartModalforType = false
      }
    },
    setHeaderConfigs(state,value){
      state.header_configs = value
    },
    setLocationChoices(state,value){
      state.location_choices = value
      
    },
    setLocation(state,value){
      let length = state.category_items.reduce((total, category) => {
          return total + category.items.length
      }, 0);
      if(length){
        state.showClearCartModal = !state.showClearCartModal
      }else{
        localStorage.setItem('location', value);
      }
        state.location = value

        localStorage.removeItem('selectedCategory')
        //localStorage.setItem('location', value);
      
    },
    closeClearCartModal(state,{status}){
      if(status){
        state.category_items = []
        state.showClearCartModal = false
        localStorage.removeItem('category_cart')
        localStorage.setItem('location', state.location);
      } else {
        state.location = parseInt(localStorage.getItem('location'))
        state.showClearCartModal = false
      }
    },
    pushProductToCart(state,{ details }){
      //state.items.push(value)
      state.items.push({
        id: details.id,
        details,
        quantity: details.quantity,
        addOns: details.addOns,
        images: details.product.product_images,
        special_instruction: details.special_instruction,
        options: details.options,
        uId: state.items.length + 1
      })
    },
    addCateringCategoryToCart(state, {details}){
      //let price = parseFloat(details.price_with_discount?details.price_with_discount:details.product.price)

      // if(details.addOns.length > 0)
      //     price = details.addOns.reduce((total,addOn)=>{
      //         console.log(addOn)
      //         return total + parseFloat(addOn.price)
      //     },price)
      state.category_items.push({
          id: details.category.id,
          name: details.category.name,
          minimum_number_of_plates: details.category.minimum_number_of_plates,
          number_of_plates: details.category.minimum_number_of_plates,
          service_charges: details.category.service_charges,
          items: [{
            item_id: details.id,
            details: details.product,
            quantity: details.quantity,
            addOns: details.addOns,
            images: details.product.product_images,
            special_instruction: details.special_instruction,
            options: details.options,
            size:details.size,
            uId:1
          }]
      })
    },
    addItemToCateringCategory(state, {details}){
        //let price = parseFloat(details.price_with_discount?details.price_with_discount:details.product.price)

        // if(details.addOns.length > 0)
        //     price = details.addOns.reduce((total,addOn)=>{
        //         return total + parseFloat(addOn.price)
        //     },price)

        let category = state.category_items.find(category => category.id === details.category.id)
        category.items.push({
          item_id: details.id,
          details: details.product,
          quantity: details.quantity,
          addOns: details.addOns,
          images: details.product.product_images,
          special_instruction: details.special_instruction,
          options: details.options,
          size:details.size,
          uId:category.items.length + 1
        })
    },
    addCateringAdditionalQty(state, { category_id, uId, quantity }){
      let category = state.category_items.find(category => category.id === category_id)
      const cartItem = category.items.find(item => item.uId === uId);
      cartItem.quantity += quantity;
      //state.items_inventory[cartItem.id] += quantity;
   },
    removeProductFromCart(state, {uId,category_id}) {
        // let item = state.items.filter(item => item.uId === uId.uId);
        // delete state.items_inventory[item[0].id];
        let category = state.category_items.find(category => category.id === category_id)
        if(category.items.length == 1)
          state.category_items = state.category_items.reduce((items, item) => {
            if (item.id !== category_id) {items.push(item)}
                return items
          }, [])
        else
          category.items = category.items.reduce((items, item) => {
              if (item.uId !== uId) {items.push(item)}
              return items
          }, [])
      state.voucher = ''
    },
    addAdditionalQty(state, { uId, quantity }){
      const cartItem = state.items.find(item => item.uId === uId);
      cartItem.quantity += quantity;
      //state.items_inventory[cartItem.id] += quantity;
   },
   incrementItemQuantity (state,  {uId,category_id} ) {
     let category = state.category_items.find(category => category.id === category_id)
      const cartItem = category.items.find(item => item.uId === uId);
      cartItem.quantity++;
      state.voucher = ''
    },
    decrementItemQuantity (state,  {uId,category_id} ) {
      let category = state.category_items.find(category => category.id === category_id)
      const cartItem = category.items.find(item => item.uId === uId);
      cartItem.quantity--;
      state.voucher = ''
        
    },
    incrementPlatesAmount(state, {category_id}){
      let category = state.category_items.find(category => category.id === category_id);
      category.number_of_plates += 1;
    },
    decrementPlatesAmount(state, {category_id}){
      let category = state.category_items.find(category => category.id === category_id);
      category.number_of_plates -= 1;
    },
    updateSearch(state,value){
      state.search = value
    },
    setDeliveryAreaConfigs(state,value){
      state.delivery_areas_configs = value
    },
    setCateringDate(state,value){
      state.catering_date = value
    },
    clearAllCart(state){
      state.category_items = []

      localStorage.removeItem('category_cart')
    },
    saveCart (state){
      localStorage.setItem('category_cart', JSON.stringify(state.category_items));
    },
    clearAllData(state){
      state.category_items = []
      localStorage.removeItem('category_cart')
      state.location = ''
      localStorage.setItem('location', '');
      localStorage.removeItem('selectedCategory')
      localStorage.setItem('delivery_type', 'delivery');
      state.delivery_type = 'delivery';
    }
  };
  
  export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions
  }
  