import Vue from 'vue';
import VueRouter from 'vue-router';

import { i18n } from './../plugins/i18n';
Vue.use(VueRouter);

const IS_ENABLED_KEYCLOAK = localStorage.getItem('isKeycloakAuth') === 'true';
const IS_LOGGED_IN = localStorage.getItem('VUE_APP_TOKEN');

const routes = [
  {
    path: '/:lang/',
    exact: true,
    redirect:
      IS_ENABLED_KEYCLOAK || IS_LOGGED_IN
        ? `/${i18n.locale}/dashboard`
        : `/${i18n.locale}/login`,
  },
  {
    name: 'login',
    path: '/:lang/login',
    exact: true,
    ...(IS_ENABLED_KEYCLOAK && {
      redirect: `/${i18n.locale}/dashboard`,
    }),
  },
  {
    name: 'forget-password',
    path: '/:lang/forget-password',
    exact: true,
    //redirect: `/${i18n.locale}/dashboard`
  },
];

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes,
});

export default router;
