import Vue from 'vue';

const directives = {
  install(Vue, options) {
    Vue.directive('loading', {
      bind: function (el, binding, vnode) {
        if (binding.value) {
          el.innerHTML =
            '<img src="/Loader-Gif.gif" alt="Ottu" height="200px"/>';
        }
      },
      update: function (el, binding, vnode) {
        if (binding.value) {
          el.innerHTML =
            '<img src="/Loader-Gif.gif" alt="Ottu" height="200px" />';
        } else {
          vnode.key += '1';
        }
      },
    });
  },
};

Vue.use(directives);
