import Vue from 'vue';
import Vuetify from 'vuetify';
// import 'vuetify/src/stylus/app.styl'
import { Ripple } from 'vuetify/lib/directives';

import 'vuetify/dist/vuetify.min.css';

Vue.use(Vuetify, {
  iconfont: 'md',
  directives: {
    Ripple,
  },
  theme: {
    primary: '#f77c1a',
  },
});
