export const getWhatsappPath = () => 'https://api.whatsapp.com/send';

export const getWhatsAppURL = (msg, phone) => {
  let path = getWhatsappPath();
  path += `?text=${encodeURIComponent(msg)}`;
  if (phone) {
    path += `&phone=${phone}`;
  }
  return path;
};

export function openInNewTab(href) {
  Object.assign(document.createElement('a'), {
    target: '_blank',
    href,
  }).click();
}
