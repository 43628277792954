import Vue from 'vue'
import Vuex from 'vuex'
import cart from './modules/cart'
import food_cart from "./modules/food_cart";
import food_cart_tmp from "@/store/modules/food_cart_tmp";
import categories_products from "./modules/categories_products";
import new_food_cart from "./modules/new_food_cart"


Vue.use(Vuex);

const debug = process.env.NODE_ENV !== 'production';

export default new Vuex.Store({
  modules: {
    cart,
    food_cart,
    new_food_cart,
    food_cart_tmp,
    categories_products
  },
  strict: debug,
  plugins: []
})
