import router from '../../router';

const state = {
  config_currency: '',
  show_terms: false,
  terms_text: {},
  items: [],
  checkoutStatus: null,
  show_banner_section: false,
  label_store: {},
  decimal_places: null,
};
if (localStorage.getItem('cart')) {
  let restoredCart = {};
  try {
    restoredCart = JSON.parse(localStorage.getItem('cart'));
  } catch (e) {
    console.error(e);
    localStorage.removeItem('cart');
  }
  if (restoredCart.items) {
    restoredCart.items.forEach((item) => {
      if (item.inventory === null) {
        item.inventory = Infinity;
      }
    });
  }
  Object.assign(state, restoredCart);
}

const getters = {
  cartProducts: (state, getters, rootState) => {
    return state.items.map(({ details, quantity }) => {
      return {
        name: details.name,
        price: details.price,
        quantity,
      };
    });
  },

  cartTotalPrice: (state, getters) => {
    return getters.cartProducts.reduce((total, product) => {
      return total + product.price * product.quantity;
    }, 0);
  },
  isProductAdded: (state) => (product) => {
    return !!state.items.find((item) => item.slug === product.slug);
  },
};

// actions
const actions = {
  addProductToCart({ state, commit }, product) {
    commit('setCheckoutStatus', null);
    if (product.inventory > 0 || product.inventory === null) {
      const cartItem = state.items.find((item) => item.slug === product.slug);
      if (!cartItem) {
        commit('pushProductToCart', { details: product });
      } else {
        router.push({ name: 'cart' }).catch((err) => {
          if (
            err &&
            err.name !== 'NavigationDuplicated' &&
            !err.message.includes(
              'Avoided redundant navigation to current location',
            )
          ) {
            console.log('Error: ', err);
          }
        });
      }
    }
    commit('saveCart');
  },
  removeProductFromCart({ state, commit }, slug) {
    commit('removeProductFromCart', slug);
    commit('saveCart');
  },
  incrementItemQuantity({ state, commit }, { slug }) {
    commit('incrementItemQuantity', { slug });
    commit('saveCart');
  },
  decrementItemQuantity({ state, commit }, { slug }) {
    commit('decrementItemQuantity', { slug });
    commit('saveCart');
  },
  clearCart({ state, commit }) {
    commit('clearCart');
    localStorage.removeItem('cart');
  },
  setCatalogueConfig(
    { state, commit },
    { currency, showBanner, label_store, decimal_places },
  ) {
    commit('setCatalogueCurrency', currency);
    commit('setShowBannerSection', showBanner);
    commit('setLabel', label_store);
    commit('setDecimalPlaces', decimal_places);
  },
  setItemQuantity({ state, commit }, { slug, qty }) {
    commit('setItemQuantity', { slug, qty });
    commit('saveCart');
  },
};

// mutations
const mutations = {
  pushProductToCart(state, { details }) {
    state.items.push({
      slug: details.slug,
      details,
      quantity: 1,
      inventory: details.inventory !== null ? details.inventory - 1 : Infinity,
    });
  },
  removeProductFromCart(state, slug) {
    state.items = state.items.reduce((items, item) => {
      if (item.slug !== slug) {
        items.push(item);
      }
      return items;
    }, []);
  },

  incrementItemQuantity(state, { slug }) {
    const cartItem = state.items.find((item) => item.slug === slug);
    cartItem.quantity++;
    cartItem.inventory--;
  },
  decrementItemQuantity(state, { slug }) {
    const cartItem = state.items.find((item) => item.slug === slug);
    cartItem.quantity--;
    cartItem.inventory++;
  },
  setItemQuantity(state, { slug, qty }) {
    const cartItem = state.items.find((item) => item.slug === slug);
    cartItem.quantity = qty;
    cartItem.inventory = 0;
  },
  setCartItems(state, { items }) {
    state.items = items;
  },

  setCheckoutStatus(state, status) {
    state.checkoutStatus = status;
  },
  saveCart(state) {
    localStorage.setItem('cart', JSON.stringify(state));
  },
  clearCart(state) {
    state.items = [];
  },
  setCatalogueCurrency(state, currency) {
    state.config_currency = currency;
  },
  setTermsText(state, terms_dict) {
    state.terms_text = terms_dict;
  },
  setShowTerms(state, showTerms) {
    state.show_terms = showTerms;
  },
  setShowBannerSection(state, showBannerSection) {
    state.show_banner_section = showBannerSection;
  },
  setLabel(state, label_store) {
    state.label_store = label_store;
  },
  setDecimalPlaces(state, decimal_places) {
    state.decimal_places = decimal_places;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
