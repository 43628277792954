const state = {
  categoriesProducts: [], //array of categories with their products
  menuTypes: [],
};
const getters = {
  getCategoryProducts: (state) => (category_id) => {
    let cat = state.categoriesProducts.find((c) => c.id === category_id);
    if (cat && cat.hasOwnProperty('products')) return cat.products;
    return null;
  },
  getCategoryProductsMenuTypes: (state) => (category_id) => {
    let categoryProductMenuTypes = state.menuTypes.find(
      (c) => c.id === category_id,
    );
    if (
      categoryProductMenuTypes &&
      categoryProductMenuTypes.hasOwnProperty('menuTypes')
    )
      return categoryProductMenuTypes.menuTypes;
    return null;
  },
};
const actions = {
  cacheCategoryProducts({ state, commit }, item) {
    if (!state.categoriesProducts.some((c) => c.id === item.id)) {
      commit('addCategoryProducts', { details: item });
    }
  },
  cacheCategoryProductsMenuTypes({ state, commit }, item) {
    if (!state.menuTypes.some((m) => m.id === item.id)) {
      commit('addCategoryProductsMenuTypes', { details: item });
    }
  },
};
const mutations = {
  addCategoryProducts(state, { details }) {
    state.categoriesProducts.push(details);
  },
  addCategoryProductsMenuTypes(state, { details }) {
    state.menuTypes.push(details);
  },
};

export default {
  namespaced: true,
  state,
  getters,
  actions,
  mutations,
};
