import { i18n } from '../../plugins/i18n';

export default {
  path: 'invoices',
  component: { template: '<router-view />' },
  name: 'invoices',
  meta: {
    page_title: 'Invoices',
    is_wrapper: true,
  },
  children: [
    {
      path: '/',
      name: 'invoices',
      component: () =>
        import(
          /* webpackChunkName: "Invoices" */ '../../views/dashboard/payment_request/InvoicesList'
        ),
      meta: {
        page_title: i18n.tc('Invoices'),
        breadcrumb_items: ['general-info', 'invoices'],
      },
    },
    {
      path: 'deleted-invoices',
      name: 'deleted-invoices',
      component: () =>
        import(
          /* webpackChunkName: "DeletedInvoices" */ '../../views/dashboard/payment_request/DeletedInvoicesList'
        ),
      meta: {
        page_title: i18n.tc('Deleted Invoices'),
        breadcrumb_items: [
          'general-info',
          'deleted-invoices',
        ],
      },
    },
  ],
};
