<template>
  <v-app>
    <v-content>
      <v-container>
        <h1>{{ $t('Page not found') }}</h1>
      </v-container>
    </v-content>
  </v-app>
</template>
<script>
  export default {
    name: "NotFound"
  }
</script>

<style scoped>

</style>