import { i18n } from '../../plugins/i18n';

export default {
  path: 'events',
  name: 'events',
  meta: {
    plugin: 'events',
  },
  component: () =>
    import(
      /* webpackChunkName: "event" */ '../../views/customer/events/Events'
    ),
  children: [
    {
      path: '/',
      name: 'categories-list',
      component: () =>
        import(
          /* webpackChunkName: "events-list" */ '../../views/customer/events/EventsCategories'
        ),
      meta: {
        plugin: 'events',
        hide_navbar: true,
      },
    },
    {
      path: 'register/:slug',
      name: 'event-register',
      meta: {
        plugin: 'events',
      },
      component: () =>
        import(
          /* webpackChunkName: "register" */ '../../views/customer/events/Registration'
        ),
    },
    {
      path: 'thanks-for-register',
      name: 'thanks-for-register',
      meta: {
        plugin: 'events',
      },
      component: () =>
        import(
          /* webpackChunkName: "thanks-for-register" */ '../../views/customer/events/ThanksRegister'
        ),
    },
    {
      path: 'leave-feedback/:slug',
      name: 'leave-feedback',
      meta: {
        plugin: 'events',
      },
      component: () =>
        import(
          /* webpackChunkName: "leave-feedback" */ '../../views/customer/events/EventFeedback'
        ),
    },
    {
      path: 'leave-feedback/:slug/thanks',
      name: 'feedback-thanks',
      meta: {
        plugin: 'events',
      },
      component: () =>
        import(
          /* webpackChunkName: "leave-thanks" */ '../../views/customer/events/components/FeedbackThanks'
        ),
    },
    {
      path: 'thankyou',
      name: 'thankyou',
      component: () =>
        import(
          /* webpackChunkName: "leave-thanks" */ '../../views/customer/events/BookingThankYou'
        ),
      props: true,
      meta: {
        page_title: i18n.tc('Thank You'),
        breadcrumb_items: ['event-list', 'thankyou'],
        plugin: 'events',
      },
    },
    {
      path: 'ticketwaitingzone',
      name: 'ticketwaitingzone',
      component: () =>
        import('../../views/customer/events/components/TicketWaitZone'),
      props: true,
      meta: {
        page_title: i18n.tc('Please wait ...'),
        breadcrumb_items: ['event-list', 'thankyou'],
        plugin: 'events',
      },
    },
    {
      path: 'terms-and-conditions',
      name: 'terms-and-conditions',
      component: () => import('../../views/customer/events/TermsAndConditions'),
      meta: {
        page_title: i18n.tc('Terms and Conditions'),
        breadcrumb_items: ['terms-and-conditions'],
        plugin: 'events',
      },
    },
    {
      path: ':category',
      name: 'event-list',
      component: () =>
        import(
          /* webpackChunkName: "events-list" */ '../../views/customer/events/EventsList'
        ),
      meta: {
        plugin: 'events',
        hide_navbar: true,
      },
    },
    {
      path: 'event/:slug',
      name: 'event-details',

      component: () =>
        import(
          /* webpackChunkName: "events-details" */ '../../views/customer/events/EventDetails'
        ),
      meta: {
        page_title: i18n.tc('Event details'),
        breadcrumb_items: ['event-list', 'event-details'],
        plugin: 'events',
      },
    },
  ],
};
