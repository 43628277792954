<template>
    <v-container>
        <v-card>
            <v-toolbar dark>
                <v-toolbar-title>{{$t('Branch Details')}}</v-toolbar-title>
                <v-spacer></v-spacer>
                <v-btn class="edit-btn" color="primary">{{$t('Edit')}}</v-btn>
            </v-toolbar>
            <v-layout row wrap justify-center style="margin-top: 20px">
                <v-flex xs12 sm5>
                    <v-img
                        class="branch-logo"
                        :src="branch_details.image"
                    />
                    <h3>{{ branch_details.name }}</h3>
                </v-flex>
                <v-flex xs12 sm5>
                    <v-flex row class="justify-space-between">
                        <p>{{$t('Email')}}</p>
                        <p>{{branch_details.email}}</p>
                    </v-flex>
                    <v-flex row class="justify-space-between">
                        <p>{{$t('Phone')}}</p>
                        <p>{{branch_details.phone}}</p>
                    </v-flex>
                    <v-flex row class="justify-space-between">
                        <p>{{$t('Address')}}</p>
                            <div v-html="branch_details.address"></div>
                    </v-flex>
<!--                    <v-flex row class="justify-space-between">-->
<!--                        <p>{{$t('Working hours')}}</p>-->
<!--                        <p>{{branch_details.working_hours.begin}} - {{branch_details.working_hours.end}}</p>-->
<!--                    </v-flex>-->
                    <v-flex row class="justify-space-between">
                        <p>{{$t('Minimum order sum')}}</p>
                        <p>{{branch_details.minimum_order_sum}}</p>
                    </v-flex>
                    <v-flex row class="justify-space-between">
                        <p>{{$t('Delivery time')}}</p>
                        <p>{{branch_details.delivery_time}}</p>
                    </v-flex>
                    <v-flex>
                        <GmapMap
                            :center="{lat:10, lng:10}"
                            :zoom="7"
                            map-type-id="terrain"
                            style="width: 500px; height: 300px"
                            ref="map"
                        >
                        </GmapMap>
                    </v-flex>
                </v-flex>
            </v-layout>
        </v-card>
    </v-container>
</template>

<script>
    import food_ordering from "../../../services/plugins/food_ordering";

    export default {
        name: "BranchDetails",
        data: () => ({
            branch_details: {},
            branch_id: undefined
        }),
        methods: {
            async getBranchDetails(){
                try {
                    const res = await food_ordering.getDashboardBranchDetails(this.branch_id)
                    this.branch_details = res.data;
                } catch (error) {
                    console.log('Error: Unable to get Dashboard branch details - ', error);
                }
            }
        },
        created() {
            this.branch_id = this.$route.params.id;
            this.getBranchDetails();
        }
    }
</script>

<style scoped>
    .branch-logo{
        width: 90%;
    }
</style>
