const state = {
  publicApiKey: null,
};

const getters = {
  publicApiKey: (state) => {
    return state.publicApiKey;
  },
};

const mutations = {
  setPublicApiKey(state, publicApiKey) {
    state.publicApiKey = publicApiKey;
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
};
