import { PluginsSerice } from "./index";
import { PLUGIN_SUBPATH } from "./common";
import { TransactionsService, DeletedTransactionService } from "./common";

const foodOrderingService = PluginsSerice(`/${PLUGIN_SUBPATH.food_ordering}`);
const dashboardService = PluginsSerice(`/${PLUGIN_SUBPATH.food_ordering}/dashboard`);

export const transactionService = new TransactionsService('food_ordering');
export const deletedTransactionService = new DeletedTransactionService('food_ordering');

export default {
  getCategories(params) {
    return foodOrderingService({
      method: 'GET',
      url: '/product_categories',
      params
    })
  },
  getCategorieById(id) {
    return foodOrderingService({
      method: 'GET',
      url: `/product_categories/${id}`,
    })
  },
  getProducts(params) {
    return foodOrderingService({
      method: 'GET',
      url: '/menu-items',
      params
    })
  },
  getProductById(id, params) {
    return foodOrderingService({
      method: 'GET',
      url: `/products/${id}`,
      params
    })
  },
  getBranches(params) {
    return foodOrderingService({
      method: 'GET',
      url: '/branches',
      params
    })
  },
  getPayChoices(data) {
    return foodOrderingService({
      method: "POST",
      url: '/pay-choices',
      data
    })
  },
  getMenuConfig() {
    return foodOrderingService.options('/products')
  },
  getHeaderConfig(params) {
    return foodOrderingService(
      {
        method: "GET",
        url: '/header-config',
        params
      })
  },
  getLocationsChoices() {
    return foodOrderingService.options('/branches')
  },
  getDeliveryAreaDetails(id) {
    return foodOrderingService.get(`/delivery_areas/${id}`);
  },
  getDeliveryAreaWorkingHours(id) {
    return foodOrderingService.get(`/delivery_zones/${id}`);
  },
  getBranchDetails(id) {
    return foodOrderingService.get(`/branches/${id}`);
  },
  getAcceptedPaymentsMethods() {
    return foodOrderingService.get('/accepted-pay-choices')
  },
  orderCheckout(data, isCatering = false) {
    if (isCatering) {
      return foodOrderingService({
        method: "POST",
        url: '/catering-checkout',
        data
      })
    }
    else {
      return foodOrderingService({
        method: "POST",
        url: '/checkout',
        data
      })
    }
  },
  getOrderDetails(number) {
    return foodOrderingService.get(`/order/details/${number}`)
  },
  getBanners() {
    return foodOrderingService.get('/banners')
  },
  getPromotions(params) {
    return foodOrderingService({
      url: '/promoted-items',
      method: 'GET',
      params
    });
  },
  getOrdersList(params) {
    return dashboardService({
      url: '/order',
      method: 'GET',
      params
    })
  },
  orderListMetadata() {
    return dashboardService.options('/order');
  },
  getDashboardOrderDetails(id) {
    return dashboardService.get(`/order/${id}`)
  },
  updateOrder(id, data) {
    return dashboardService({
      method: 'PATCH',
      url: `/order/update/${id}`,
      data
    })
  },
  getBranchesList() {
    return dashboardService.get('branches')
  },
  getDashboardBranchDetails(id) {
    return dashboardService.get(`/branches/${id}`)
  },
  getMinOrder(params) {
    return foodOrderingService({
      url: 'min-order',
      method: 'GET',
      params
    });
  },
  testFoodOrderingInstallation() {
    return foodOrderingService.get('/test-if-food-ordering-installed')
  },
  uploadProductBulk(data) {
    const formData = new FormData();
    formData.append('file', data.file);
    return foodOrderingService.post(
      '/upload/product',
      formData,
      { headers: { 'Content-Type': 'multipart/form-data' } }
    )
  },
  activateVoucher(code, params) {
    return foodOrderingService({
      url: `voucher-list/${code}`,
      method: 'GET',
      params
    })
  },
  getFeedbackConfig(order_number) {
    return foodOrderingService.options(`order-feedback/${order_number}`);
  },
  createOrderFeedback(data, order_number) {
    return foodOrderingService({
      url: `order-feedback/${order_number}`,
      method: 'POST',
      data
    })
  },
  getOrderState(number) {
    return foodOrderingService.get(`/order/state/${number}`);
  },
  updateOrderState(number) {
    return foodOrderingService.put(`order/change-status/${number}`)
  },
  getMenuPageConfig() {
    return foodOrderingService.get('menu-page-config');
  },
  checkIfBranchOrDeliveryAreaWork(params) {
    return foodOrderingService({
      url: 'is-branch-or-delivery-area-work',
      method: "GET",
      params
    })
  },
  checkIfUserHasScanPermission(data) {
    return foodOrderingService.get(`order/validate/${data}`);
  }
}

export const productExampleLink = foodOrderingService.defaults.baseURL + '/export-format';
